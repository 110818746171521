import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import { Modal } from "antd";
import { Card, Flex, Space, Table, Button, DatePicker, message } from "antd";
import { PencilSquare } from "react-bootstrap-icons";
import { ColorRing } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { ExclamationCircleFilled } from "@ant-design/icons";
import FacilitatorService from "../../../services/FacilitatorService";
import UserService from "../../../services/UserService";
import FetchClient from "../../../serviceClients/FetchClient";
import AddTeamMember from "./AddTeamMember";

function TeamMembers({ members, teamId }) {
  const navigate = useNavigate();
  const { instance } = useMsal();
  const { confirm } = Modal;
  const [messageApi, contextHolder] = message.useMessage();
  const [onOpenCreateTeamMemberModal, setOnOpenCreateTeamMemberModal] =
    useState(false);
  const [teamMembers, setTeamMembers] = useState(
    members && members.length > 0 ? members : []
  );
  const [teamMemberToUpdate, setTeamMemberToUpdate] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
      showTotal: (total, range) =>
        `Viewing ${range[0]}-${range[1]} of ${total} results`,
      pageSizeOptions: [10, 20, 50, 100],
    },
  });
  const [selectedTeamMembers, setSelectedTeamMembers] = useState([]);
  let activeAccount;
  if (instance) {
    activeAccount = instance.getActiveAccount();
  }

  function showDeleteModal() {
    showConfirm();
  }
  async function deleteTeamMember() {
    setIsLoading(true);
    let newMembers = teamMembers.filter(
      (member) => !selectedTeamMembers.some((selected) => selected.id == member.id)
    );

    try {
      await updateTeamMembers(newMembers);
      setTeamMembers(newMembers);
    } catch (error) {
      setError("Couldn't add team member");
    } finally {
      setSelectedTeamMembers([]);
      setIsLoading(false);
      setOnOpenCreateTeamMemberModal(false);
    }
    setIsLoading(false);
  }

  //HANDLE TABLE CHANGE
  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({ pagination, filters, ...sorter });

    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setTeamMembers([]);
    }
  };

  //HANDLE EDIT COURSE BUTTON CLICK
  const handleEditClick = async (record) => {
    console.log(record);
    setTeamMemberToUpdate({
      ...record,
      user: record.id,
      startDate: dayjs(record.startDate),
      endDate: record.endDate ? dayjs(record.endDate) : null,
    });
    setOnOpenCreateTeamMemberModal(true);
  };
  const handleNameClick = async (record) => {
    navigate("/profile/edit", {
      state: {
        userData: record,
      },
    });
  };

  //SET EVENT LIST TABLE COLUMNS
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (_, record) => <div onClick={() => handleNameClick(record)}>{record.name}</div>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, record) => <span className="capitalize">{determineMembershipStatus(record)}</span>,
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
    },
    {
      title: "Note",
      dataIndex: "note",
      key: "note",
    },
    {
      title: "",
      key: "edit",
      render: (_, record) => (
        <Space size="middle">
          <PencilSquare
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleEditClick(record);
            }}
          />
        </Space>
      ),
    },
  ];

  function determineMembershipStatus(teamMember) {
    const currentDate = new Date();
    let status = 'Non-Member'; // Default status
    // Check if there are any dues
    if (teamMember.dues && teamMember.dues.length > 0) {
        let hasValidMembership = false;
        let hasExpiredMembership = false;
        let hasLifetimeMembership = false;

        for (const due of teamMember.dues) {
            const expiryDate = new Date(due.expiryDate); // Assuming each due has an expiryDate property
            
            // Check for Lifetime Membership
            if (due.type === 'Lifetime') {
                hasLifetimeMembership = true;
                break; // No need to check further if Lifetime Membership exists
            }
            
            // Check for valid or expired membership
            if (expiryDate >= currentDate) {
                hasValidMembership = true;
            } else if (expiryDate < currentDate) {
                hasExpiredMembership = true;
            }
        }

        // Determine status based on the checks
        if (hasLifetimeMembership) {
            status = 'Member';
        } else if (hasValidMembership) {
            status = 'Member';
        } else if (hasExpiredMembership) {
            status = 'Expired';
        }
    }

    return status;
}


  //CONFIRM MODAL
  const showConfirm = (key) => {
    confirm({
      className: "confirmModalStyle",
      centered: true,
      icon: <ExclamationCircleFilled />,
      content: "Do you want to delete this team member?",
      okText: "Yes",
      cancelText: "No",
      onOk() {
        console.log("ok");
        deleteTeamMember();
      },
      onCancel() {
        console.log("Cancel");
      },
      width: 500,
    });
  };

  const onCancelCreateTeamMemberModal = () => {
    setOnOpenCreateTeamMemberModal(false);
    setTeamMemberToUpdate({});
  };

  const getUser = async (id) => {
    try {
      const _fetchInstance = new FetchClient(activeAccount.idToken);
      const _userService = new UserService(_fetchInstance);
      const user = await _userService.getUserById(id);
      return user;
    } catch(error){
      console.log(error);
      if (error.code === 401) {
        localStorage.removeItem("loggedUser");
        await instance.logout({
          onRedirectNavigate: () => {
            return false;
          }
        });
        await instance.loginRedirect();
      }
      return null;
    }
  }

  const onCreateTeamMember = async (values) => {
    setIsLoading(true);
    let newMembers = [];
    if (teamMembers && teamMembers.length > 0) 
      newMembers = [...teamMembers];

    try {
      const teamMember = await getUser(values.id);
      newMembers.push({
        id: values.id,
        name: values.user,
        startDate: dayjs(values.startDate).utc('z').toISOString(),
        endDate: values.endDate ? dayjs(values.endDate).utc('z').toISOString() : null,
        note: values.note,
        dues: teamMember.dues ?? [],
      });
      await updateTeamMembers(newMembers);
      setTeamMembers(newMembers);
    } catch (error) {
      setError("Couldn't add team member");
    } finally {
      setIsLoading(false);
      setOnOpenCreateTeamMemberModal(false);
      setTeamMemberToUpdate({});
    }
  };
  const onEditTeamMember = async (member) => {
    setIsLoading(true);
    let newMembers = [];
    if (teamMembers && teamMembers.length > 0)
      newMembers = teamMembers.filter(
        (teamMember) => teamMember.id != member.originalId
      );
    try {
      const teamMember = await getUser(member.id);
      newMembers.push({
        id: member.id,
        name: member.user,
        startDate: dayjs(member.startDate).utc('z').toISOString(),
        endDate: member.endDate ? dayjs(member.endDate).utc('z').toISOString() : null,
        note: member.note,
        dues: teamMember.dues ?? [],
      });
      await updateTeamMembers(newMembers);
      setTeamMembers(newMembers);
    } catch (error) {
      setError("Couldn't update team member");
    } finally {
      setIsLoading(false);
      setOnOpenCreateTeamMemberModal(false);
      setTeamMemberToUpdate({});
    }
  };

  async function updateTeamMembers(members) {
    const _fetchInstance = new FetchClient(activeAccount.idToken);
    const _facilitatorService = new FacilitatorService(_fetchInstance);

    try {
      //DELETE USER
      await _facilitatorService.updateFacilitator({
        id: teamId,
        teamMembers: members,
      });
      messageApi.open({
        type: "success",
        content: "Course deleted successfully!",
        className: "custom-class",
        duration: "5",
        style: {
          marginTop: "20vh",
        },
      });
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      messageApi.open({
        type: "error",
        content: "Something went wrong. Please try again later.",
        className: "custom-class",
        duration: "5",
        style: {
          marginTop: "20vh",
        },
      });

      if (error.code === 401) {
        localStorage.removeItem("loggedUser");
        await instance.logout({
          onRedirectNavigate: () => {
            return false;
          }
        });
        await instance.loginRedirect();
      }
    }
  }

  //HANDLE COURSE LIST SELECTION
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedTeamMembers(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled Course",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  if (isLoading) {
    return (
      <Card style={{ padding: "20px" }}>
        {contextHolder}
        <Flex vertical gap="30px">
          <Flex justify="center" vertical align="center">
            <ColorRing
              visible={true}
              height="80"
              width="80"
              ariaLabel="color-ring-loading"
              wrapperStyle={{}}
              wrapperClass="color-ring-wrapper"
              colors={["#26225A", "#66618D", "#545A6D", "#BCBEC0"]}
            />
          </Flex>
        </Flex>
      </Card>
    );
  }

  if (error) {
    return (
      <Card>
        {contextHolder}
        <Flex>
          <Flex>
            <div className="centered text-4xl h-screen">
              Something went wrong! Please try again.
            </div>
          </Flex>
        </Flex>
      </Card>
    );
  }

  return (
    <>
      <AddTeamMember
        onOpenCreateTeamMemberModal={onOpenCreateTeamMemberModal}
        onCancelCreateTeamMemberModal={onCancelCreateTeamMemberModal}
        onCreateTeamMember={onCreateTeamMember}
        onEditTeamMember={onEditTeamMember}
        teamMember={teamMemberToUpdate}
        teamMembers={teamMembers}
      />
      <span>Team Members</span>
      <div
        style={{ width: "100%", border: "0.5px solid", marginBottom: "30px" }}
      />
      <Flex vertical gap="30px">
        <Flex vertical align="flex-start">
          <div className="course-table">
            <Space
              size="small"
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              {selectedTeamMembers.length > 0 && (
                <Button
                  onClick={showDeleteModal}
                  className="confirmUserActionsButton"
                  style={{ marginBottom: "8px" }}
                >
                  Delete
                </Button>
              )}
              <Button
                onClick={() => setOnOpenCreateTeamMemberModal(true)}
                className="confirmButton"
                style={{ marginBottom: "8px" }}
              >
                Add
              </Button>
            </Space>
            <Table
              rowClassName={"tableRowActiveCourse"}
              rowSelection={{
                type: "checkbox",
                ...rowSelection,
              }}
              columns={columns}
              dataSource={teamMembers}
              rowKey={(record) => record.id}
              pagination={tableParams.pagination}
              onChange={handleTableChange}
            />
          </div>
        </Flex>
      </Flex>
    </>
  );
}
export default TeamMembers;
