import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import moment from "moment";
import dayjs from "dayjs";
import { ExclamationCircleFilled } from "@ant-design/icons";
import EventService from "../../services/EventService";
import FetchClient from "../../serviceClients/FetchClient";
import {
  Card,
  Flex,
  Input,
  Select,
  Space,
  Table,
  Button,
  DatePicker,
  message,
} from "antd";
import { Header } from "antd/es/layout/layout";
import CustomHeader from "../../components/Header";
import { PencilSquare } from "react-bootstrap-icons";
import { ColorRing } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";

function ClassList() {
  const navigate = useNavigate();
  const { instance } = useMsal();
  const { confirm } = Modal;
  const { RangePicker } = DatePicker;
  const [messageApi, contextHolder] = message.useMessage();
  const [events, setEvents] = useState([]);
  const [allEvents, setAllEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const [eventDateSearchText, setEventDateSearchText] = useState(null);
  const [eventNameSearchText, setEventNameSearchText] = useState("");
  const [eventTypeSearchText, setEventTypeSearchText] = useState("");
  const [facilitatorsSearchText, setFacilitatorsearchText] = useState("");
  const [selectedEventTypeValues, setSelectedEventTypeValues] = useState(null);
  const [selectedStatusValues, setSelectedStatusValues] = useState(null);
  const [statusSearchText, setStatusSearchText] = useState("");
  const [error, setError] = useState();
  const [tableParams, setTableParams] = useState({
    pagination: {
      position: ["bottomRight"],
      pageSizeOptions: [10, 20, 50, 100],
    },
  });
  const [selectedEvents, setSelectedEvents] = useState([]);
  let activeAccount;
  if (instance) {
    activeAccount = instance.getActiveAccount();
  }

  function handleStatusSearchChange(value) {
    setSelectedStatusValues(value);
    setStatusSearchText(value);
  }

  function handleEventTypeSearchChange(value) {
    setSelectedEventTypeValues(value);
    setEventTypeSearchText(value);
  }

  function handleScheduleChange(value) {
    if (value == null || value == "null") {
      setEventDateSearchText(null);
    } else {
      const date = dayjs(new Date(value)).format("L"); //;
      setEventDateSearchText(date);
    }
  }

  function getEventDate(event) {
    return `${moment(
      new Date(
        Math.min.apply(
          null,
          event.schedules.map((schedule) => new Date(schedule.startDate))
        )
      )
    ).format("L")} - ${moment(
      new Date(
        Math.max.apply(
          null,
          event.schedules.map((schedule) => new Date(schedule.endDate))
        )
      )
    ).format("L")}`;
  }

  //GET COURSE LIST
  async function fetchEvents() {
    setIsLoading(true);

    const _fetchInstance = new FetchClient(activeAccount.idToken);
    //CREATE COURSE SERVICE INSTANCE
    const eventService = new EventService(_fetchInstance);

    try {
      const eventData = await eventService.getAllEvents();
      setEvents(
        eventData.map((event) => {
          if (event.schedules && event.schedules.length > 0) {
            return {
              ...event,
              eventDate: getEventDate(event),
            };
          } else {
            return {
              ...event,
              eventDate: "",
            };
          }
        })
      );
      setAllEvents(eventData);
      setTableParams({
        ...tableParams,
        pagination: { ...tableParams.pagination },
      });
      setIsLoading(false);
    } catch (error) {
      setError(error);
      setIsLoading(false);
      if (error.code === 401) {
        localStorage.removeItem("loggedUser");
        await instance.logout({
          onRedirectNavigate: () => {
            return false;
          }
        });
        await instance.loginRedirect();
      }
    }
  }

  function showDeleteModal() {
    showConfirm();
  }

  function clearFilters() {
    setEventDateSearchText(null);
    setEventNameSearchText("");
    setEventTypeSearchText("");
    setFacilitatorsearchText("");
    setStatusSearchText("");
    setSelectedStatusValues(null);
    setSelectedEventTypeValues(null);
  }

  async function deleteCourse() {
    setIsLoading(true);
    setSelectedEvents([]);
    const _fetchInstance = new FetchClient(activeAccount.idToken);
    const eventService = new EventService(_fetchInstance);

    try {
      //DELETE USER
      await eventService.deleteEvent(selectedEvents[0]);
      messageApi.open({
        type: "success",
        content: "Course deleted successfully!",
        className: "custom-class",
        duration: "5",
        style: {
          marginTop: "20vh",
        },
      });
    } catch (error) {
      console.error(error);
      messageApi.open({
        type: "error",
        content: "Something went wrong. Please try again later.",
        className: "custom-class",
        duration: "5",
        style: {
          marginTop: "20vh",
        },
      });

      if (error.code === 401) {
        localStorage.removeItem("loggedUser");
        await instance.logout({
          onRedirectNavigate: () => {
            return false;
          }
        });
        await instance.loginRedirect();
      }
    } finally {
      fetchEvents();
    }
  }

  useEffect(() => {
    const loggedUser = JSON.parse(localStorage.getItem("loggedUser"));
    if (loggedUser.role === "admin") {
      setIsAdmin(true);
      fetchEvents();
    } else {
      setIsAdmin(false);
    }
  }, [activeAccount.idToken,
    // tableParams.pagination?.current,
    // tableParams.pagination?.pageSize,
  ]);

  //HANDLE TABLE CHANGE
  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({ pagination, filters, ...sorter });

    // if (pagination.pageSize !== tableParams.pagination?.pageSize) {
    //   setEvents([]);
    // }
  };

  //HANDLE EDIT COURSE BUTTON CLICK
  const handleEditClick = async (record) => {
    await localStorage.setItem("eventId", record.id);
    navigate("/addEvent", {
      state: {
        event: record,
      },
    });
  };

  const onEditFacilitator = async (facilitator, type) => {
    if (type === "user") {
      await localStorage.setItem("facilitatorUser", JSON.stringify(facilitator));
      window.open(window.location.origin + "/profile/edit", "_blank", "noreferrer")
    } else {
      await localStorage.setItem("facilitatorTeam", JSON.stringify(facilitator));
      window.open(window.location.origin + "/addFacilitatorTeam", "_blank", "noreferrer")
    }
  };

  const columns = [
    {
      title: "Event Schedule Dates",
      dataIndex: "eventDate",
      key: "eventDate",
      defaultSortOrder: "descend",
      filteredValue: [eventDateSearchText],
      onFilter: (value, record) => {
        return value == null || value == "null"
          ? true
          : record.schedules?.some((schedule) => {
              return (
                dayjs(schedule.startDate).isSame(dayjs(value), 'day')
              );
            });
      },
      sorter: (a, b) => dayjs(Math.min.apply(null, a.schedules.map((schedule) => dayjs(schedule.startDate)))).valueOf() - dayjs(Math.min.apply(null, b.schedules.map((schedule) => dayjs(schedule.startDate))).valueOf())
    },
    {
      title: "Event",
      dataIndex: "eventName",
      key: "eventName",
      filteredValue: [eventNameSearchText],
      onFilter: (value, record) => {
        return record.eventName?.toLowerCase().includes(value.toLowerCase());
      },
      sorter: (a, b) => a.eventName.localeCompare(b.eventName),
    },
    {
      title: "Type",
      dataIndex: "eventType",
      key: "eventType",
      filteredValue: [eventTypeSearchText],
      onFilter: (value, record) => {
        return value == undefined || value == "undefined"
          ? true
          : record.eventType?.toLowerCase().includes(value.toLowerCase());
      },
      sorter: (a, b) => a.eventType.localeCompare(b.eventType),
    },
    {
      title: "Facilitators",
      dataIndex: "facilitators",
      key: "facilitators",
      filteredValue: [facilitatorsSearchText],
      onFilter: (value, record) => {
        return (
          record.facilitators
            ?.map(
              (facilitator) =>
                `${facilitator.firstName} ${facilitator.lastName}`
            )
            .join(", ")
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          record.teamFacilitators
            ?.map((facilitator) => facilitator.name)
            .join(", ")
            .toLowerCase()
            .includes(value.toLowerCase())
        );
      },
      render: (_, record) => (
        <span>
            {
              record.facilitators && record.facilitators.length > 0
                ? record.facilitators
                    .map((facilitator, idx) => (
                      <span style={{cursor: 'pointer'}} onClick={() => onEditFacilitator(facilitator, "user")}>
                        {`${facilitator.firstName} ${facilitator.lastName}`}
                        {
                          record.facilitators.length - 1 != idx 
                          ? ", "
                          : ""
                        }
                      </span>
                    ))
                : ""
            }
            {
              record.teamFacilitators && record.teamFacilitators.length > 0
              ? record.teamFacilitators
                  .map((teamFacilitator, idx) => (
                    <span style={{cursor: 'pointer'}} onClick={() => onEditFacilitator(teamFacilitator, "team")}>
                      {record.facilitators && record.facilitators.length > 0 ? ", " : ""}{teamFacilitator.name}
                    </span>
                  ))
              : ""
            }
        </span>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      filteredValue: [statusSearchText],
      onFilter: (value, record) => {
        if (value && value != "") {
          return value.split(",").some(v => v.toLowerCase() == record.status?.toLowerCase());
        } else {
          return true;
        }
      },
      sorter: (a, b) => a.status.localeCompare(b.status),
    },
    {
      title: "",
      key: "edit",
      render: (_, record) => (
        <Space size="middle">
          <PencilSquare
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleEditClick(record);
            }}
          />
        </Space>
      ),
    },
  ];

  //CONFIRM MODAL
  const showConfirm = (key) => {
    confirm({
      className: "confirmModalStyle",
      centered: true,
      icon: <ExclamationCircleFilled />,
      content: "Do you want to delete this course?",
      okText: "Yes",
      cancelText: "No",
      onOk() {
        deleteCourse();
      },
      onCancel() {
        console.log("Cancel");
      },
      width: 500,
    });
  };

  //HANDLE COURSE LIST SELECTION
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedEvents(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled Course",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  if (isLoading) {
    return (
      <Card style={{ padding: "20px" }}>
        {contextHolder}
        <Flex vertical gap="30px">
          <Flex justify="center" vertical align="center">
            <ColorRing
              visible={true}
              height="80"
              width="80"
              ariaLabel="color-ring-loading"
              wrapperStyle={{}}
              wrapperClass="color-ring-wrapper"
              colors={["#26225A", "#66618D", "#545A6D", "#BCBEC0"]}
            />
          </Flex>
        </Flex>
      </Card>
    );
  }

  if (!isAdmin) {
    return (
      <Card>
        {contextHolder}
        <Flex>
          <Flex>
            <div className="centered text-4xl h-screen">
              <span style={{fontSize: 16, fontWeight: 'bold'}}>
                You are not authorized to access this page.
              </span>
            </div>
          </Flex>
        </Flex>
      </Card>
    );
  }

  if (error) {
    return (
      <Card>
        {contextHolder}
        <Flex>
          <Flex>
            <div className="centered text-4xl h-screen">
              Something went wrong! Please try again.
            </div>
          </Flex>
        </Flex>
      </Card>
    );
  }

  return (
    <>
      <Header className="profileMenuBar">
        <CustomHeader
          items={[{ title: "Classes & Events"}]}
        />
      </Header>
      <Card style={{ padding: "20px" }}>
        {contextHolder}
        <Flex vertical gap="30px">
          <Flex vertical align="flex-start">
            <div className="course-table">
              <Space
                direction="vertical"
                size="small"
                style={{ display: "flex" }}
              >
                <Space
                  size="small"
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                  }}
                >
                  <Button
                    onClick={() => clearFilters()}
                    className="confirmButton"
                    style={{ marginBottom: "8px" }}
                    >
                    Clear
                  </Button>
                  <Button
                    onClick={() => {
                      localStorage.removeItem("eventId");
                      navigate("/addEvent");
                    }}
                    className="confirmButton"
                    style={{ marginBottom: "8px" }}
                    >
                    Add Event +
                  </Button>
                  {selectedEvents.length === 1 && (
                    <Button
                      onClick={showDeleteModal}
                      className="confirmButton"
                      style={{ marginBottom: "8px" }}
                    >
                      Delete
                    </Button>
                  )}
                </Space>
                <Space size="small" align="center" style={{
                    display: "flex",
                  }}>
                  <DatePicker
                    className="userListSearchBox"
                    placeholder="Schedule Start Date"
                    format={"MM/DD/YYYY"}
                    onChange={handleScheduleChange}
                    style={{ width: "200px" }}
                    value={eventDateSearchText ? dayjs(eventDateSearchText) : null}
                    allowClear
                  ></DatePicker>
                  <Input.Search
                    placeholder="Name"
                    className="userListSearchBox"
                    onSearch={(value) => {
                      setEventNameSearchText(value);
                    }}
                    onChange={(e) => {
                      setEventNameSearchText(e.target.value);
                    }}
                    style={{ width: "200px" }}
                    value={eventNameSearchText}
                  />
                  <Select
                    placeholder="Type"
                    className="userListSearchBox"
                    onChange={handleEventTypeSearchChange}
                    options={[
                      {
                        label: "Class",
                        value: "Class",
                      },
                      {
                        label: "Event",
                        value: "Event",
                      },
                    ]}
                    allowClear
                    style={{ width: "100px" }}
                    value={selectedEventTypeValues}
                  ></Select>
                  <Input.Search
                    placeholder="Facilitators"
                    className="userListSearchBox"
                    onSearch={(value) => {
                      setFacilitatorsearchText(value);
                    }}
                    onChange={(e) => {
                      setFacilitatorsearchText(e.target.value);
                    }}
                    style={{ width: "200px" }}
                    value={facilitatorsSearchText}
                  />
                  <Select
                    mode="multiple"
                    placeholder="Status"
                    className="userListSearchBox"
                    onChange={handleStatusSearchChange}
                    options={[
                      {
                        label: "New Application",
                        value: "New Application",
                      },
                      {
                        label: "Draft",
                        value: "Draft",
                      },
                      {
                        label: "Open",
                        value: "Open",
                      },
                      {
                        label: "Closed",
                        value: "Closed",
                      },
                      {
                        label: "Completed",
                        value: "Completed",
                      },
                      {
                        label: "Cancelled",
                        value: "Cancelled",
                      },
                      {
                        label: "Rejected",
                        value: "Rejected",
                      },
                      {
                        label: "Permitted",
                        value: "Permitted",
                      },
                      {
                        label: "In Review",
                        value: "In Review",
                      },
                    ]}
                    allowClear
                    style={{ width: "150px" }}
                    value={selectedStatusValues}
                  ></Select>
                </Space>
              </Space>
              <Table
                rowClassName={"tableRowActiveCourse"}
                rowSelection={{
                  type: "checkbox",
                  ...rowSelection,
                }}
                columns={columns}
                dataSource={events}
                rowKey={(record) => record.id}
                pagination={tableParams.pagination}
                onChange={handleTableChange}
              />
            </div>
          </Flex>
        </Flex>
      </Card>
    </>
  );
}
export default ClassList;
