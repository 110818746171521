const config = require("../config");

class UserService {
  constructor(httpClient) {
    this.BASE_URL = config.BASE_URL;
    this.httpClient = httpClient;
  }

  async getUsers() {
    try {
      const response = await this.httpClient.get(
        this.BASE_URL + "/GetAllUsers?"
      );
      if (response.status === 401) {
        const error = new Error("Unauthorized");
        error.code = 401;
        throw error;
      }
      else if (!response.ok) {
        const error = new Error("Failed to fetch users.");
        error.code = 500;
        throw error;
      }

      const users = await response.json();
      return users;
    } catch (error) {
      console.error("Error fetching users: ", error);
      if (error.code === 401) {
        // const error = new Error("Unauthorized");
        // error.code = 401;
        throw error;
      }
      return [];
    }
  }
  async getUsersForExport() {
    try {
      const response = await this.httpClient.get(
        this.BASE_URL + "/GetAllUsersForExport?"
      );
      if (response.status === 401) {
        const error = new Error("Unauthorized");
        error.code = 401;
        throw error;
      }
      else if (!response.ok) {
        const error = new Error("Failed to fetch users.");
        error.code = 500;
        throw error;
      }

      const users = await response.json();
      return users;
    } catch (error) {
      console.error("Error fetching users: ", error);
      if (error.code === 401) {
        // const error = new Error("Unauthorized");
        // error.code = 401;
        throw error;
      }
      return [];
    }
  }

  async getUserMembers() {
    try {
      const response = await this.httpClient.get(
        this.BASE_URL + "/GetAllMembers?"
      );
      if (response.status === 401) {
        const error = new Error("Unauthorized");
        error.code = 401;
        throw error;
      }
      else if (!response.ok) {
        const error = new Error("Failed to fetch users.");
        error.code = 500;
        throw error;
      }

      const users = await response.json();
      return users;
    } catch (error) {
      console.error("Error fetching users: ", error);
      if (error.code === 401) {
        // const error = new Error("Unauthorized");
        // error.code = 401;
        throw error;
      }
      return [];
    }
  }

  async getUserByEmail(email) {
    try {
      const response = await this.httpClient.get(
        this.BASE_URL + "/GetUserByEmail?email=" + email
      );

      if (response.status === 401) {
        const error = new Error("Unauthorized");
        error.code = 401;
        throw error;
      }

      if (!response.ok) {
        throw new Error("Failed to fetch user data.");
      }

      const user = await response.json();
      return user;
    } catch (error) {
      console.error("Error fetching user: ", error);
      if (error.code === 401)
        throw error;
      else
        return null;
    }
  }

  async getUserById(id) {
    try {
      const response = await this.httpClient.get(
        this.BASE_URL + "/GetUserById?id=" + id
      );

      if (response.status === 401) {
        const error = new Error("Unauthorized");
        error.code = 401;
        throw error;
      }

      if (!response.ok) {
        throw new Error("Failed to fetch user data.");
      }

      const user = await response.json();
      return user;
    } catch (error) {
      console.error("Error fetching user: ", error);
      if (error.code === 401)
        throw error;
      else
        return null;
    }
  }

  async createUser(user) {
    try {
      const response = await this.httpClient.post(
        this.BASE_URL + "/CreateUser",
        user
      );

      if (response.status === 401) {
        const error = new Error("Unauthorized");
        error.code = 401;
        throw error;
      }

      if (response.status === 409) {
        return null
      }
      
      return response.json();
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async createUserInAD(user) {
    try {
      const response = await this.httpClient.get(
        this.BASE_URL +
          "/CreateUserInAD?email=" +
          user.email +
          "&firstName=" +
          user.firstName +
          "&lastName=" +
          user.lastName
      );
      return response;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async batchUpdateUsers(usersActionModel) {
    try {
      console.log ("batch called.");

      const response = await this.httpClient.post(
        this.BASE_URL + "/BatchUserUpdate",
        usersActionModel
      );
      console.log (response.json().text);
      if (response.status === 401) {
        const error = new Error("Unauthorized");
        error.code = 401;
        throw error;
      }

      return response;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async updateUser(user) {
    try {
      const response = await this.httpClient.post(
        this.BASE_URL + "/UpdateUser",
        user
      );

      if (response.status === 401) {
        const error = new Error("Unauthorized");
        error.code = 401;
        throw error;
      }

      return response.json();
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async updatePassword(passwordModel) {
    try {
      const response = await this.httpClient.post(
        this.BASE_URL + "/UpdatePassword",
        passwordModel
      );

      if (response.status === 401) {
        const error = new Error("Unauthorized");
        error.code = 401;
        throw error;
      }

      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}

export default UserService;
