import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import dayjs from "dayjs";
import { v4 as uuidv4 } from 'uuid';
import { ExclamationCircleFilled } from "@ant-design/icons";
import UserService from "../../services/UserService";
import FetchClient from "../../serviceClients/FetchClient";
import {
  Card,
  Flex,
  Space,
  Table,
  Button,
  message,
} from "antd";
import { PencilSquare } from "react-bootstrap-icons";
import { ColorRing } from "react-loader-spinner";
import { useMsal } from "@azure/msal-react";
import CreateDue from "./CreateDue";

function Dues({ 
  userData,
  isSameAdminUser,
  onFinishDues 
}) {
  const { instance } = useMsal();
  const { confirm } = Modal;
  const [messageApi, contextHolder] = message.useMessage();
  const [due, setDue] = useState({});
  const [dues, setDues] = useState([]);
  const [editDue, setEditDue] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [createDueModal, setCreateDueModal] = useState(false);
  const [error, setError] = useState();
  const [tableParams, setTableParams] = useState({
    pagination: {
      position: ["bottomRight"],
      pageSizeOptions: [10, 20, 50, 100],
    },
  });
  const [selectedDues, setSelectedDues] = useState([]);
  let activeAccount;
  if (instance) {
    activeAccount = instance.getActiveAccount();
  }

  async function fetchDues() {
    setIsLoading(true);
    try {
      setDues(userData?.dues || []);
      setTableParams({
        ...tableParams,
        pagination: { ...tableParams.pagination },
      });
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  }

  function showDeleteModal() {
    showConfirm();
  }

  async function deleteDue() {
    setIsLoading(true);
    setSelectedDues([]);
    let newDuesArray = dues.filter((due) => {
      return !selectedDues.some((selectedDue) => selectedDue.id == due.id);
    })
    setIsLoading(false);
    updateUserDues(newDuesArray);
  }

  useEffect(() => {
    fetchDues();
  }, [
    activeAccount.idToken,
    tableParams.pagination?.current,
    tableParams.pagination?.pageSize,
  ]);

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({ pagination, filters, ...sorter });

    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setDues([]);
    }
  };

  const handleEditClick = (record) => {
    setEditDue(record);
    setCreateDueModal(true);
  };

  const columns = [
    {
      title: "Membership Type",
      dataIndex: "type",
      key: "type",
      sorter: (a, b) => a.type.localeCompare(b.type),
    },
    {
      title: "Date Effective",
      dataIndex: "dateEffective",
      key: "dateEffective",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.dateEffective.localeCompare(b.dateEffective),
      render: (_, record) => (
        <>
          <span>{dayjs(record.dateEffective).utc().format('MM/DD/YYYY')}</span>
        </>
      ),
    },
    {
      title: "Expiry Date",
      dataIndex: "expiryDate",
      key: "expiryDate",
      sorter: (a, b) => a.expiryDate.localeCompare(b.expiryDate),
      render: (_, record) => (
        <>
          <span>{record.expiryDate ? dayjs(record.expiryDate).utc().format('MM/DD/YYYY') : ""}</span>
        </>
      ),
    },
    {
      title: "",
      key: "edit",
      render: (_, record) => (
        <Space size="middle">
          <PencilSquare
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleEditClick(record);
            }}
          />
        </Space>
      ),
    },
  ];

  const showConfirm = (key) => {
    confirm({
      className: "confirmModalStyle",
      centered: true,
      icon: <ExclamationCircleFilled />,
      content: "Are you sure you would like to delete these Membership Dues?",
      okText: "Yes",
      cancelText: "No",
      onOk() {
        deleteDue();
      },
      onCancel() {
        console.log("Cancel");
      },
      width: 500,
    });
  };

  const onCloseDueCreateModal = () => {
    setEditDue({});
    setDue({});
    setCreateDueModal(false);
  };

  const onCreateDue = async () => {
    setIsLoading(true);
    setEditDue({});
    let loggedUser = JSON.parse(localStorage.getItem("loggedUser"));
    let newDue = {
      ...due,
      id: uuidv4(),
      dateEffective: dayjs(due.dateEffective).utc('z').toISOString(),
      expiryDate: dayjs(due.expiryDate).utc('z').toISOString(),
      creationDate: dayjs().utc('z').toISOString(),
      createdBy: `${loggedUser.firstName} ${loggedUser.lastName}`,
    }
    setDue({});
    let newDuesArray = [...dues, newDue];
    await updateUserDues(newDuesArray);
    onCloseDueCreateModal();
  };


  const updateUserDues = async (newDuesArray) => {
    setIsLoading(true);
    const _fetchInstance = new FetchClient(activeAccount.idToken);
    const _userService = new UserService(_fetchInstance);
    let createdDue = {};
    try {
      createdDue = await _userService.updateUser({
        ...userData,
        dues: newDuesArray,
      });
      messageApi.open({
        type: "success",
        content: "Due created successfully!",
        className: "custom-class",
        duration: "5",
        style: {
          marginTop: "20vh",
        },
      });
      await onFinishDues(createdDue);
      setDues(newDuesArray);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      messageApi.open({
        type: "error",
        content: "Something went wrong. Please try again later.",
        className: "custom-class",
        duration: "5",
        style: {
          marginTop: "20vh",
        },
      });

      if (error.code === 401) {
        localStorage.removeItem("loggedUser");
        await instance.logout({
          onRedirectNavigate: () => {
            return false;
          }
        });
        await instance.loginRedirect();
      } else {
        await onFinishDues(createdDue);
        setDues(newDuesArray);
        setIsLoading(false);
      }
    }
  }

  const onEditDue = async () => {
    setIsLoading(true);
    let newDuesArray = dues.map((originalDue) => (originalDue.id === editDue.id ? due : originalDue))
    setDues(newDuesArray);
    await updateUserDues(newDuesArray);

    onCloseDueCreateModal();
  };
  const handleCreateDueInputChange = (e) => {
    const { name, value } = e.target;
    setDue({ ...due, [name]: value });
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedDues(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled Due",
      name: record.name,
    }),
  };

  if (isLoading) {
    return (
      <Card style={{ padding: "20px" }}>
        {contextHolder}
        <Flex vertical gap="30px">
          <Flex justify="center" vertical align="center">
            <ColorRing
              visible={true}
              height="80"
              width="80"
              ariaLabel="color-ring-loading"
              wrapperStyle={{}}
              wrapperClass="color-ring-wrapper"
              colors={["#26225A", "#66618D", "#545A6D", "#BCBEC0"]}
            />
          </Flex>
        </Flex>
      </Card>
    );
  }

  if (error) {
    return (
      <Card>
        {contextHolder}
        <Flex>
          <Flex>
            <div className="centered text-4xl h-screen">
              Something went wrong! Please try again.
            </div>
          </Flex>
        </Flex>
      </Card>
    );
  }

  return (
    <>
      {contextHolder}
      <span style={{fontWeight: 'bold'}}>Dues</span>
      <div
        style={{ width: "100%", border: "0.5px solid", marginBottom: "30px" }}
      />
      <Flex vertical gap="30px">
        <Flex vertical align="flex-start">
          <div className="course-table">
            <Space
              direction="vertical"
              size="small"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              {!isSameAdminUser && (
                <Button
                  onClick={() => setCreateDueModal(true)}
                  className="confirmButton"
                  style={{ marginBottom: "8px" }}
                >
                  Add Due +
                </Button>
              )}
              {selectedDues.length >= 1 && (
                <Button
                  onClick={showDeleteModal}
                  className="confirmButton"
                  style={{ marginBottom: "8px" }}
                >
                  Delete
                </Button>
              )}
            </Space>
            <Table
              rowClassName={"tableRowActiveDue"}
              rowSelection={!isSameAdminUser ? {type: "checkbox", ...rowSelection} : null}
              columns={columns}
              dataSource={dues}
              rowKey={(record) => record.id}
              pagination={false}
              onChange={handleTableChange}
            />
            <CreateDue
              onOpenCreateDueModal={createDueModal}
              onCancelCreateDueModal={onCloseDueCreateModal}
              onEditDue={onEditDue}
              onCreateDue={onCreateDue}
              onInputChange={handleCreateDueInputChange}
              setDue={setDue}
              due={due}
              editDue={editDue}
            />
          </div>
        </Flex>
      </Flex>
    </>
  );
}
export default Dues;
