import { LogLevel } from "@azure/msal-browser";

export const authoritiesConfig = {
  development: {
    signIn: {
      authority:
        "https://bpctidev.b2clogin.com/bpctidev.onmicrosoft.com/B2C_1_SIGN_IN",
    },
    signUp: {
      authority:
        "https://bpctidev.b2clogin.com/bpctidev.onmicrosoft.com/B2C_1_SIGN_UP",
    },
    signUpSignIn: {
      authority:
        "https://bpctidev.b2clogin.com/bpctidev.onmicrosoft.com/B2C_1_SIGN_UP_SIGN_IN",
    },
    forgotPassword: {
      authority:
        "https://bpctidev.b2clogin.com/bpctidev.onmicrosoft.com/B2C_1_PASSWORD_RESET",
    },
    newUserPassword: {
      authority:
        "https://bpctidev.b2clogin.com/bpctidev.onmicrosoft.com/B2C_1_NEW_USER_PASSWORD",
    },
    authorityDomain: "bpctidev.b2clogin.com",
  },
  production: {
    signIn: {
      authority:
        "https://bpctiserverlessprod.b2clogin.com/bpctiserverlessprod.onmicrosoft.com/B2C_1_SIGN_IN",
    },
    signUp: {
      authority:
        "https://bpctiserverlessprod.b2clogin.com/bpctiserverlessprod.onmicrosoft.com/B2C_1_SIGN_UP",
    },
    signUpSignIn: {
      authority:
        "https://bpctiserverlessprod.b2clogin.com/bpctiserverlessprod.onmicrosoft.com/B2C_1_SIGN_UP_SIGN_IN",
    },
    forgotPassword: {
      authority:
        "https://bpctiserverlessprod.b2clogin.com/bpctiserverlessprod.onmicrosoft.com/B2C_1_PASSWORD_RESET",
    },
    newUserPassword: {
      authority:
        "https://bbpctiserverlessprod.b2clogin.com/bpctiserverlessprod.onmicrosoft.com/B2C_1_NEW_USER_PASSWORD",
    },
    authorityDomain: "bpctiserverlessprod.b2clogin.com",
  },
  uat: {},
};

export const b2cPolicies = {
  names: {
    signIn: "B2C_1_SIGN_IN",
    signUp: "B2C_1_SIGN_UP",
    signUpSignIn: "B2C_1_SIGN_UP_SIGN_IN",
    forgotPassword: "B2C_1_PASSWORD_RESET",
    newUserPassword: "B2C_1_NEW_USER_PASSWORD",
  },
  authorities: {
    signIn: {
      authority: authoritiesConfig[process.env.NODE_ENV].signIn.authority,
    },
    signUp: {
      authority: authoritiesConfig[process.env.NODE_ENV].signUp.authority,
    },
    signUpSignIn: {
      authority: authoritiesConfig[process.env.NODE_ENV].signUpSignIn.authority,
    },
    forgotPassword: {
      authority:
        authoritiesConfig[process.env.NODE_ENV].forgotPassword.authority,
    },
  },
  authorityDomain: authoritiesConfig[process.env.NODE_ENV].authorityDomain,
};

export const msalConfig = {
  auth: {
    clientId: "432eb7a7-1ee6-4814-8ab4-165b5e90aa39",
    authority: b2cPolicies.authorities.signIn.authority,
    knownAuthorities: [b2cPolicies.authorityDomain],
    redirectUri: "/",
    postLogoutRedirectUri: "https://dbm.concept-therapy.org/",
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            // console.error(message);
            return;
          case LogLevel.Info:
            // console.info(message);
            return;
          case LogLevel.Verbose:
            // console.debug(message);
            return;
          case LogLevel.Warning:
            // console.warn(message);
            return;
          default:
            return;
        }
      },
    },
  },
};

export const protectedResources = {
  graphUser: {
    endpoint: "https://graph.microsoft.com/v1.0/users",
    scopes: ["User.ReadWrite.All"],
  },
};
