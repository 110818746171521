import { Button, Form, Input, Modal, Select, DatePicker, Radio } from "antd";
import UserService from "../../services/UserService";
import FetchClient from "../../serviceClients/FetchClient";
import dayjs from "dayjs";
import { participantRoles } from "../../utils/constants";
import React, { useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import "react-phone-input-2/lib/style.css";

const { TextArea } = Input;

function CreateParticipant({
  onOpenCreateParticipantModal,
  onCancelCreateParticipantModal,
  onCreateParticipant,
  onEditParticipant,
  onInputChange,
  onDateInputChange,
  onSelectRoleChange,
  setParticipant,
  editParticipant,
  participant,
  participants,
  onSelectChange,
}) {
  const { instance } = useMsal();
  const [form] = Form.useForm();
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [isEditMode, setIsEditMode] = useState(false);
  const [users, setUsers] = useState([]);
  const [credit, setCredit] = useState(false);
  const [feePaid, setFeePaid] = useState(false);
  const [isUserError, setIsUserError] = useState(false);

  let activeAccount;
  if (instance) {
    activeAccount = instance.getActiveAccount();
  }
  
  const cancelModal = () => {
    form.resetFields();
    setIsSaveDisabled(true);
    setCredit(false);
    setFeePaid(false);
    onCancelCreateParticipantModal();
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    if (onOpenCreateParticipantModal) {
      setIsEditMode(editParticipant && Object.keys(editParticipant).length > 0);
      if (editParticipant && Object.keys(editParticipant).length > 0) {
        setParticipant(editParticipant);
        setFeePaid(editParticipant.feePaid);
        setCredit(editParticipant.credit);
        form.setFieldsValue({
          userId: editParticipant.userId,
          role: editParticipant.role,
          feePaid: editParticipant.feePaid ?? false,
          credit: editParticipant.credit ?? false,
          note: editParticipant.note,
          paymentDate: editParticipant.paymentDate ? dayjs(editParticipant.paymentDate).utc() : null,
        })
      }
      else {
        setCredit(false);
        setFeePaid(false);
        form.setFieldsValue({
          credit: false,
          feePaid: false
        });
      }
    }
  }, [onOpenCreateParticipantModal]);

  async function fetchUsers() {
    const _fetchInstance = new FetchClient(activeAccount.idToken);
    //CREATE USER SERVICE INSTANCE
    const userService = new UserService(_fetchInstance);

    try {
      const usersData = await userService.getUsers();
      setUsers(
        usersData.map((user) => {
          return {
            label: `${user.firstName} ${user.lastName}`,
            value: user.id,
          };
        })
      );
    } catch (error) {
      console.log(error);
      if (error.code === 401) {
        localStorage.removeItem("loggedUser");
        await instance.logout({
          onRedirectNavigate: () => {
            return false;
          }
        });
        await instance.loginRedirect();
      }
    }
  }

  const setButtonDisabled = () => {
    let participantValues = form.getFieldsValue();
    if (isUserError)
      setIsSaveDisabled(true);
    else if (!participantValues.userId || !participantValues.role || participantValues.feePaid == null || participantValues.credit == null)
      setIsSaveDisabled(true);
    else if (participantValues.feePaid && !participantValues.paymentDate)
      setIsSaveDisabled(true);
    else 
      setIsSaveDisabled(false);
  }

  return (
    <div className="App">
      <Modal
        style={{ color: "#26225A" }}
        title={isEditMode ? "Edit Participant" : "Add Participant"}
        centered
        destroyOnClose={true}
        open={onOpenCreateParticipantModal}
        onOk={isEditMode ? onEditParticipant : onCreateParticipant}
        onCancel={() => cancelModal()}
        footer={[
          <Button
            key="cancel"
            type="primary"
            style={{ background: "#26225A", borderColor: "white" }}
            onClick={() => cancelModal()}
          >
            Cancel
          </Button>,
          <Button
            htmlType="submit"
            key="submit"
            className="confirmButton"
            onClick={
              isEditMode
                ? () => onEditParticipant()
                : () => onCreateParticipant()
            }
            disabled={isSaveDisabled}
          >
            {isEditMode ? "Save" : "Add"}
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="userId"
            label="Participant"
            rules={[
              {
                required: true,
                message: "Please select a participant",
              },
              {
                validator: async () => {
                  const participantValues = form.getFieldsValue();
                  if (participantValues.userId && participants.some((participant) => participant.userId === participantValues.userId)) {
                    setIsUserError(true);
                    setIsSaveDisabled(true);
                    return Promise.reject(
                      new Error(
                        "Participant already exists."
                      )
                    );
                  }
                  else {
                    setIsUserError(false);
                    if (!participantValues.userId || !participantValues.role || participantValues.feePaid == null || participantValues.credit == null)
                      setIsSaveDisabled(true);
                    else if (participantValues.feePaid && !participantValues.paymentDate)
                      setIsSaveDisabled(true);
                    else 
                      setIsSaveDisabled(false);
                  }
                },
              }
            ]}
          >
            <Select
              name="userId"
              showSearch
              optionFilterProp="label"
              placeholder="Search Users"
              onChange={(e) => {
                form.setFieldValue("userId", e);
                onSelectChange(e);
                setButtonDisabled();
              }}
              options={users}
            />
          </Form.Item>
          <Form.Item
            name="role"
            label="Role"
            rules={[
              {
                required: true,
                message: "Please select a role",
              },
            ]}
          >
            <Select
              name="role"
              showSearch
              optionFilterProp="label"
              placeholder="Role"
              onChange={(e) => {
                form.setFieldValue("role", e);
                onSelectRoleChange(e);
                setButtonDisabled();
              }}
              options={participantRoles}
            />
          </Form.Item>
          <div
            size="small"
            align="center"
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "10px",
            }}
          >
            <Form.Item
              label="Fee Paid"
              name="feePaid"
              valuePropName="checked"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-start",
              }}
              rules={[
                {
                  required: true,
                }
              ]}
            >
              <Radio.Group
                onChange={(v) => {
                  if (v.target.value === false) {
                    setParticipant({ ...participant, feePaid: v.target.value, paymentDate: null });
                    form.setFieldsValue({
                      feePaid: v.target.value,
                      paymentDate: null,
                    });
                  }
                  else {
                    setParticipant({ ...participant, feePaid: v.target.value });
                    form.setFieldValue("feePaid", v.target.value);
                  }
                  setFeePaid(v.target.value);
                  setButtonDisabled();
                }}
                value={feePaid}
              >
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </Form.Item>
            {feePaid && (
              <Form.Item
                style={{
                  width: "100%",
                }}
                name="paymentDate"
                label="Payment Date"
                rules={[
                  {
                    required: feePaid,
                  },
                ]}
              >
                <DatePicker
                  name="paymentDate"
                  format={"MM/DD/YYYY"}
                  className="userListSearchBox"
                  style={{ width: "100%" }}
                  onChange={(e) => { 
                    onDateInputChange(e);
                    setButtonDisabled();
                  }}
                ></DatePicker>
              </Form.Item>
            )}
          </div>
          <Form.Item
            label="Credit"
            name="credit"
            valuePropName="checked"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Radio.Group
              onChange={(v) => {
                setParticipant({ ...participant, credit: v.target.value });
                setCredit(v.target.value);
                form.setFieldValue("credit", v.target.value);
                setButtonDisabled();
              }}
              value={credit}
            >
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item label="Note" name="note">
            <TextArea
              style={{ height: "80px" }}
              name="note"
              placeholder="Note"
              onChange={onInputChange}
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default CreateParticipant;
