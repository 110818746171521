import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import UserService from "../../../services/UserService";
import FetchClient from "../../../serviceClients/FetchClient";
import {
  Card,
  Flex,
  Input,
  Select,
  Space,
  Table,
  DatePicker,
  Button,
  Dropdown,
  message,
  Tooltip
} from "antd";
import { Header } from "antd/es/layout/layout";
import CustomHeader from "../../../components/Header";
import { DownOutlined } from "@ant-design/icons";
import { PencilSquare } from "react-bootstrap-icons";
import prospectIcon from "../../../images/prospecticon.png";
import beamerIcon from "../../../images/beamericon.png";
import pathSeekerIcon from "../../../images/pathseekericon.png";
import teacherIcon from "../../../images/teachericon.png";
import { ColorRing } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import {
  accountLevelOptions,
  activeInactiveUserOptions,
  activeUserOptions,
  inactiveUserOptions,
  noUserSelectedOptions,
  titleOptions,
} from "../../../shared/selectOptions";

function UserList() {
  const { instance } = useMsal();
  const { RangePicker } = DatePicker;
  const [messageApi, contextHolder] = message.useMessage();
  const [users, setUsers] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [activeState, setActiveState] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const [userActionsItems, setUserActionsItems] = useState(
    noUserSelectedOptions
  );
  const [error, setError] = useState();
  const [nameSearchText, setNameSearchText] = useState("");
  const [citySearchText, setCitySearchText] = useState("");
  const [stateSearchText, setStateSearchText] = useState("");
  const [accountLevelSerchText, setAccountLevelSearchText] = useState("");
  const [accountLevelStartDate, setAccountLevelStartDate] = useState("");
  const [accountLevelEndDate, setAccountLevelEndDate] = useState("");
  const [selectedAccountLvelValues, setSelectedAccountLevelValues] = useState(
    []
  );
  const [countrySearchText, setCountrySearchText] = useState("");
  const [titleSerchText, setTitleSearchText] = useState("");
  const [selectedTitleValues, setSelectedTitleValues] = useState([]);
  const [tableParams, setTableParams] = useState({
    pagination: {
      position: ["bottomRight"],
      pageSizeOptions: [10, 20, 50, 100],
    },
  });
  const [selectedUsers, setSelectedUsers] = useState("");

  const navigate = useNavigate();

  let activeAccount;
  if (instance) {
    activeAccount = instance.getActiveAccount();
  }

  //GET USER LIST
  async function fetchUsers() {
    setIsLoading(true);

    const _fetchInstance = new FetchClient(activeAccount.idToken);
    //CREATE USER SERVICE INSTANCE
    const userService = new UserService(_fetchInstance);

    try {
      const usersData = await userService.getUsers();
      setUsers(usersData);
      setAllUsers(usersData);
      setTableParams({
        ...tableParams,
        pagination: { ...tableParams.pagination },
      });
    } catch (error) {
      console.log("fetchUsers error:", error);
      if (error.code === 401) {
        localStorage.removeItem("loggedUser");
        await instance.logout({
          onRedirectNavigate: () => {
            return false;
          }
        });
        await instance.loginRedirect();
      }
      setError(error);
    } finally {
      if (activeState == 1) {
        handleActiveButtonClick()
      } else if (activeState == 0) {
        handleInactiveButtonClick()
      }
      setIsLoading(false);
    }
  }

   useEffect(() => {
     const loggedUser = JSON.parse(localStorage.getItem("loggedUser"));
     if (loggedUser.role === "admin") {
       console.log("admin user");
       setIsAdmin(true);
       fetchUsers();
     }
  }, []);

  useEffect(() => {
    const loggedUser = JSON.parse(localStorage.getItem("loggedUser"));
    if (loggedUser.role === "admin") {
      console.log("admin user2");
      setIsAdmin(true);
      fetchUsers();
    } else {
      setIsAdmin(false);
    }
  }, [
    activeAccount.idToken,
    // tableParams.pagination?.current,
    // tableParams.pagination?.pageSize,
  ]);

  //HANDLE TABLE CHANGE
  const handleTableChange = (pagination, filters, sorter, extra) => {
    setTableParams({ pagination, filters, ...sorter });

    // if (pagination.pageSize !== tableParams.pagination?.pageSize) {
    //   setUsers([]);
    // }
  };
  
  // HANDLE  ACCOUNT LEVEL DATE RANGE CHANGE
  const handleAccountLevelRangeChange = (_, info) => {
    setAccountLevelStartDate(info[0] ? dayjs(info[0]) : '');
    setAccountLevelEndDate(info[1] ? dayjs(info[1]) : '');
  };

  //HANDLE EDIT USER BUTTON CLICK
  const onEditUser = async (record) => {
    const _fetchInstance = new FetchClient(activeAccount.idToken);
    const _userService = new UserService(_fetchInstance);
    const userToEdit = await _userService.getUserById(record.id);
    navigate("/profile/edit", { state: { userData: userToEdit } });
  };

  //HANDLE INACTIVE BUTTON CLICK
  const handleInactiveButtonClick = () => {
    var inactiveUsers = allUsers.filter((user) => {
      return !user.isActive;
    });
    setActiveState(0)
    setUsers(inactiveUsers);
  };

  //HANDLE ACTIVE BUTTON CLICK
  const handleActiveButtonClick = () => {
    var activeUsers = allUsers.filter((user) => {
      return user.isActive;
    });
    setActiveState(1)
    setUsers(activeUsers);
  };
  
  //HANDLE ALL USERS BUTTON CLICK
  const handleAllUsersButtonClick = () => {
    setUsers(allUsers);
    setActiveState(null)
  };

  //HANDLE TITLE DROPDOWN CHANGE
  const handleTitleSearchChange = (value) => {
    setTitleSearchText(value);
    setSelectedTitleValues(value);
  };

  //HANDLE ACCOUNT LEVEL SELECT CHANGE
  const handleAccountLevelSearchChange = (value) => {
    if (!value) {
      setAccountLevelSearchText("");
      setSelectedAccountLevelValues([]);
    } else {
      setAccountLevelSearchText(value);
      setSelectedAccountLevelValues(value);
    }
  };

  //HANDLE CLEAR FILTERS CLICK
  const clearFilters = () => {
    setCitySearchText("");
    setStateSearchText("");
    setCountrySearchText("");
    setAccountLevelSearchText("");
    setTitleSearchText("");
    setNameSearchText("");
    setSelectedTitleValues([]);
    setSelectedAccountLevelValues([]);
    setAccountLevelStartDate("");
    setAccountLevelEndDate("");
    setUsers(allUsers);
    setActiveState(null)
  };

  const items = userActionsItems;

  const handleMenuClick = async (e) => {
    if (
      selectedUsers !== null &&
      selectedUsers !== undefined &&
      selectedUsers !== "" &&
      selectedUsers.length > 0
    ) {
      setIsLoading(true);
      const _fetchInstance = new FetchClient(activeAccount.idToken);
      const _userService = new UserService(_fetchInstance);

      try {
        let usersActionModel = {
          users: selectedUsers,
          action: e.key.toLowerCase(),
        };

        await _userService.batchUpdateUsers(usersActionModel);
        setSelectedUsers("");
        fetchUsers();
        messageApi.open({
          type: "success",
          content: "Users updated successfully!",
          className: "custom-class",
          duration: "5",
          style: {
            marginTop: "20vh",
          },
        });
        
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        messageApi.open({
          type: "error",
          content: "Something went wrong. Please try again later.",
          className: "custom-class",
          duration: "5",
          style: {
            marginTop: "20vh",
          },
        });
        setIsLoading(false);

        if (error.code === 401) {
          localStorage.removeItem("loggedUser");
          await instance.logout({
            onRedirectNavigate: () => {
              return false;
            }
          });
          await instance.loginRedirect();
        }
      }
    }
    else if (e.key.toLowerCase() === "download") {
      const _fetchInstanceDwn = new FetchClient(activeAccount.idToken);
      const _userServiceDwn = new UserService(_fetchInstanceDwn);
      const usersToExport = await _userServiceDwn.getUsersForExport();
      let filteredUsers = getFilteredUsers(usersToExport);
      let csvData = generateCsv(filteredUsers);
      downloadCsv(csvData);
    }
  };

  const getFilteredUsers = (users) => {
    
    let filteredUsers = users.filter((user) => {
      let fullName = user.firstName?.toLowerCase() + " " + user.lastName?.toLowerCase();
      const nameArray = fullName.split(" ");
      if (nameArray.length > 2) {
        fullName = nameArray[0] + " " + nameArray[nameArray.length - 1];
      }
      else{
        fullName = nameArray[0] + " " + nameArray[1];
      }
      return (
        user.shippingCity
        ? user.shippingCity?.toLowerCase().includes(citySearchText.toLowerCase())
        : true
      ) && (
        user.shippingState
        ? user.shippingState?.toLowerCase().includes(stateSearchText.toLowerCase())
        : true
      ) && (
        user.shippingCountry
        ? user.shippingCountry?.toLowerCase().includes(countrySearchText.toLowerCase())
        : true
      ) &&
      (
        user.firstName?.toLowerCase().includes(nameSearchText.toLowerCase()) ||
        user.lastName?.toLowerCase().includes(nameSearchText.toLowerCase()) ||
        fullName.includes(nameSearchText.toLowerCase()) ||
        `${user.firstName} ${user.lastName}`
          .toLowerCase()
          .includes(nameSearchText.toLowerCase()) ||
        user.email?.toLowerCase().includes(nameSearchText.toLowerCase())
      ) &&
      (
        selectedTitleValues.length > 0 
          ? selectedTitleValues.some(title => title.toLowerCase() == user.title?.toLowerCase()) 
          : true
      ) &&
      user.accountLevel.some(level => level.toLowerCase().includes(accountLevelSerchText.toLowerCase())) &&
      getFilteredUsersByDate(user, accountLevelStartDate, accountLevelEndDate);
    });

    return filteredUsers;
  }

  const downloadCsv = (data) => {
    const blob = new Blob([data], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);    
    const a = document.createElement('a');
    a.href = url;
    a.download = 'download'+ getFormattedDateTime() + '.csv';    
    a.click();
  }
  
  const generateCsv = (users) => {
    let formattedUsers = formatUsers(users);
    let csvRows = [];
    const csvHeaders = [
      "Title", 
      "FirstName", 
      "MiddleName", 
      "LastName",
      "Suffix",
      "Email",
      "IsLifeTimeMember",
      "ProspectDate",
      "BeamerDate",
      "PathseekerDate",
      "MembershipStatus",
      "IsActive",
  //    "BillingFirstName",
  //    "BillingLastName",
      "BillingAddress1",
      "BillingAddress2",
      "BillingCity",
      "BillingState",
      "BillingZipcode",
      "BillingCountry",
      "Deceased",
      "ReceivePhysicalMail",
      "ReceiveDigitalMail",
      "TeachingStatus",
    ];

    csvRows.push(csvHeaders.join(","));
    setIsLoading(false);
    formattedUsers.forEach(user => {
      const values = Object.values(user).join(',');
      csvRows.push(values);
    });
    return csvRows.join('\n');
  }

  const formatUsers = (users) => {
    let newUsers = [];
    newUsers = users.map((user) => {
      return {
        title: user.title ?? "",
        firstName: replaceComma(user.firstName) ?? "",
        middleName: replaceComma(user.middleName) ?? "",
        lastName: replaceComma(user.lastName) ?? "",
        suffix: user.suffix ?? "",
        email: user.email ?? "",
        isLifeTimeMember: user.isLifeTimeMember ? "Yes" : "No",
        prospectDate: user.prospectDate ?? "",
        beamerDate: user.beamerDate ?? "",
        pathSeekerDate: user.pathSeekerDate ?? "",
        membershipStatus: determineMembershipStatus(user),
        isActive: user.isActive ? "Yes" : "No",
       // billingFirstName: replaceComma(user.billingFirstName) ?? "",
      //  billingLastName: replaceComma(user.billingLastName) ?? "",
        billingAdress1: replaceComma(user.billingAdress1) ?? "",
        billingAdress2: replaceComma(user.billingAdress2) ?? "",
        billingCity: replaceComma(user.billingCity) ?? "",
        billingState: user.billingState ?? "",
        billingZipCode: user.billingZipCode ?? "",
        billingCountry: user.billingCountry ?? "",
        deceased: user.deceased ? "Yes" : "No",
        receivePhysicalMail: user.receivePhysicalMail ? "Yes" : "No",
        receiveDigitalMail: user.receiveDigitalMail ? "Yes" : "No",
        teachingStatus: user.teachingStatus ?? "",
      };
    })

    return newUsers;
  }

const replaceComma = (stringValue) =>{
  if (!stringValue || stringValue == "" || stringValue == null || stringValue == "undefined") {
    return stringValue;
  }
  else {
    if (stringValue.includes(",")) 
    {
      return stringValue.replace(","," ");
    }
      
    else { 
      return stringValue;
    }
  }
}

const getFormattedDateTime = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const day = String(now.getDate()).padStart(2, '0');
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const seconds = String(now.getSeconds()).padStart(2, '0');
  return `${year}-${month}-${day}-${hours}-${minutes}-${seconds}`;
  }

  const determineMembershipStatus = (user) => {
    const currentDate = new Date();
    let status = 'Non-Member'; // Default status

    // Check if there are any dues
    if (user.dues && user.dues.length > 0) {
        let hasValidMembership = false;
        let hasExpiredMembership = false;
        let hasLifetimeMembership = false;

        for (const due of user.dues) {
            const expiryDate = new Date(due.expiryDate); // Assuming each due has an expiryDate property
            
            // Check for Lifetime Membership
            if (due.type === 'Lifetime') {
                hasLifetimeMembership = true;
                break; // No need to check further if Lifetime Membership exists
            }
            
            // Check for valid or expired membership
            if (expiryDate >= currentDate) {
                hasValidMembership = true;
            } else if (expiryDate < currentDate) {
                hasExpiredMembership = true;
            }
        }

        // Determine status based on the checks
        if (hasLifetimeMembership) {
            status = 'Member';
        } else if (hasValidMembership) {
            status = 'Member';
        } else if (hasExpiredMembership) {
            status = 'Expired';
        }
    }

    return status;
  }

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  const getFilteredUsersByDate = (user) => {
    if (!accountLevelStartDate || accountLevelStartDate === "" || accountLevelStartDate == "undefined") {
      return true;
    }
    else if (accountLevelSerchText == "prospect" && user.prospectDate) {
      return isBetweenDates(user.prospectDate, accountLevelStartDate, accountLevelEndDate);
    }
    else if (accountLevelSerchText == "beamer" && user.beamerDate) {
      return isBetweenDates(user.beamerDate, accountLevelStartDate, accountLevelEndDate);
    }
    else if (accountLevelSerchText == "pathSeeker" && user.pathSeekerDate) {
      return isBetweenDates(user.pathSeekerDate, accountLevelStartDate, accountLevelEndDate);
    }
    else if (accountLevelSerchText == "teacher" && user.teacherDate) {
      return isBetweenDates(user.teacherDate, accountLevelStartDate, accountLevelEndDate);
    }
    else if (!accountLevelSerchText || accountLevelSerchText == "" || accountLevelStartDate == "undefined") {
      return isBetweenDates(user.prospectDate, accountLevelStartDate, accountLevelEndDate) || 
        isBetweenDates(user.beamerDate, accountLevelStartDate, accountLevelEndDate) ||
        isBetweenDates(user.pathSeekerDate, accountLevelStartDate, accountLevelEndDate) ||
        isBetweenDates(user.teacherDate, accountLevelStartDate, accountLevelEndDate);          
    }
  }

  const hasProspectDate = (record) => {
    return (
      record.prospectDate &&
      record.prospectDate !== null &&
      record.prospectDate !== "" &&
      !record.teacherDate &&
      !record.beamerDate &&
      !record.pathSeekerDate
    );
  };
  const hasBeamerDate = (record) => {
    return (
      record.beamerDate &&
      record.beamerDate !== null &&
      record.beamerDate !== ""
    );
  };
  const hasPathSeekerDate = (record) => {
    return (
      record.pathSeekerDate &&
      record.pathSeekerDate !== null &&
      record.pathSeekerDate !== ""
    );
  };

  const isBetweenDates = (accountDate, startDate, endDate ) => {
    if (!accountDate || accountDate == "" || accountDate == null)
      return false;
    
    let newAccountDate = dayjs(accountDate).format("YYYY-MM-DD");
    let newStartDate = dayjs(startDate).format("YYYY-MM-DD");
    let newEndDate = dayjs(endDate).format("YYYY-MM-DD");

    return newAccountDate >= newStartDate && newAccountDate <= newEndDate;
  };

  //SET USER LIST TABLE COLUMNS
  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      filterSearch: true,
      filteredValue: [titleSerchText],
      onFilter: (value, record) => {
        if (value && value != "") {
          return value.split(",").some(v => v.toLowerCase() == record.title?.toLowerCase());
        } else {
          return true;
        }
      },
      sorter: (a, b) => a.title?.localeCompare(b.title),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      // filtered: true,
      filteredValue: [nameSearchText],
      onFilter: (value, record) => { 
      if (value.length > 3 ) {
      let fullName = record.firstName?.toLowerCase() + " " + record.lastName?.toLowerCase();
      const nameArray = fullName.split(" ");
      if (nameArray.length > 2) {
        fullName = nameArray[0] + " " + nameArray[nameArray.length - 1];
      }
      else{
        fullName = nameArray[0] + " " + nameArray[1];
      }
        return (
          record.firstName?.toLowerCase().includes(value.toLowerCase()) ||
          record.lastName?.toLowerCase().includes(value.toLowerCase()) ||
          fullName.includes(value.toLowerCase()) ||
          `${record.firstName} ${record.lastName}`
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          record.email?.toLowerCase().includes(value.toLowerCase())
        );
      }
      else {
        return true;
      }
      },
      sorter: (a, b) => a.firstName.localeCompare(b.firstName),
      render: (_, record) => (
        <span>
          <div>
            {record.firstName} {record.lastName}
          </div>
          <div>{record.email}</div>
        </span>
      ),
    },
    {
      title: "Account Level",
      dataIndex: "accountLevel",
      key: "accountLevel",
      filteredValue: [accountLevelSerchText],
      onFilter: (value, record) => {
        if (!value || value === "" || value == "undefined") {
          return true;
        } else if (value === "teacher") {
          return (
            record.teacherDate &&
            record.teacherDate !== null &&
            record.teacherDate !== ""
          );
        } else if (value === "pathSeeker") {
          return hasPathSeekerDate(record);
        } else if (value === "beamer") {
          return hasBeamerDate(record);
        } else if (value === "prospect") {
          return hasProspectDate(record);
        }
      },
      render: (accountLevel, record) => 
        accountLevel.map((level) => {
          let date = "";
          let htmlIcons = "";
          if (level.toUpperCase() === "PROSPECT") {
            date = record.prospectDate;
            htmlIcons = htmlIcons + `<img src=${prospectIcon} alt="P"/>`;
          }
          else if (level.toUpperCase() === "BEAMER") {
            date = record.beamerDate;
            htmlIcons = htmlIcons + `<img src=${beamerIcon} alt="B"/>`;
          }
          else if (level.toUpperCase() === "PATHSEEKER") {
            date = record.pathSeekerDate;
            htmlIcons = htmlIcons + `<img src=${pathSeekerIcon} alt="PS"/>`;
          }

          else if (level.toUpperCase() === "TEACHER") {
            date = record.teacherDate;
            htmlIcons = htmlIcons + `<img src=${teacherIcon} alt="T"/>`;
          }
          return (
            <Tooltip placement="top" title={dayjs(date).utc().format('MM/DD/YYYY')}>
              <span
                className="accountLevelIcons"
                dangerouslySetInnerHTML={{ __html: htmlIcons }}
              ></span>
            </Tooltip>
          );
        }),
    },
    {
      title: "Date",
      dataIndex: "accountDate",
      key: "accountDate",
      hidden: true,
      filteredValue: [accountLevelStartDate],
      onFilter: (value, record) => {
        if (!accountLevelStartDate || accountLevelStartDate === "" || accountLevelStartDate == "undefined") {
          return record;
        }
        else if (accountLevelSerchText == "prospect" && record.prospectDate) {
          return isBetweenDates(record.prospectDate, accountLevelStartDate, accountLevelEndDate);
        }
        else if (accountLevelSerchText == "beamer" && record.beamerDate) {
          return isBetweenDates(record.beamerDate, accountLevelStartDate, accountLevelEndDate);
        }
        else if (accountLevelSerchText == "pathSeeker" && record.pathSeekerDate) {
          return isBetweenDates(record.pathSeekerDate, accountLevelStartDate, accountLevelEndDate);
        }
        else if (accountLevelSerchText == "teacher" && record.teacherDate) {
          return isBetweenDates(record.teacherDate, accountLevelStartDate, accountLevelEndDate);
        }
        else if (!accountLevelSerchText || accountLevelSerchText == "" || accountLevelStartDate == "undefined") {
          return isBetweenDates(record.prospectDate, accountLevelStartDate, accountLevelEndDate) || 
            isBetweenDates(record.beamerDate, accountLevelStartDate, accountLevelEndDate) ||
            isBetweenDates(record.pathSeekerDate, accountLevelStartDate, accountLevelEndDate) ||
            isBetweenDates(record.teacherDate, accountLevelStartDate, accountLevelEndDate);          
        }
      },
    },
    {
      title: "City",
      dataIndex: "shippingCity",
      key: "shippingCity",
      filteredValue: [citySearchText],
      onFilter: (value, record) => {
        if (!value || value === "") {
          return record;
        }
        return record.shippingCity?.toLowerCase().includes(value.toLowerCase());
      },
      sorter: (a, b) => a.shippingCity?.localeCompare(b.shippingCity),
    },
    {
      title: "State",
      dataIndex: "shippingState",
      key: "shippingState",
      filteredValue: [stateSearchText],
      onFilter: (value, record) => {
        if (!value || value === "") {
          return record;
        }
        return record.shippingState?.toLowerCase().includes(value.toLowerCase());
      },
      sorter: (a, b) => a.shippingState?.localeCompare(b.shippingState),
    },
    {
      title: "Country",
      dataIndex: "shippingCountry",
      key: "shippingCountry",
      filteredValue: [countrySearchText],
      onFilter: (value, record) => {
        if (!value || value === "") {
          return record;
        }
        return record.shippingCountry
          ?.toLowerCase()
          .includes(value.toLowerCase());
      },
      sorter: (a, b) => a.shippingCountry?.localeCompare(b.shippingCountry),
    },
    {
      title: "Edit",
      key: "edit",
      render: (_, record) => (
        <Space size="middle">
          <PencilSquare
            style={{ cursor: "pointer" }}
            onClick={() => {
              onEditUser(record);
            }}
          />
        </Space>
      ),
    },
  ];

  function checkUserStatus(users) {
    let hasActive = false;
    let hasInactive = false;

    for (let user of users) {
      if (user.isActive) {
        hasActive = true;
      } else {
        hasInactive = true;
      }

      if (hasActive && hasInactive) {
        return "both";
      }
    }

    if (hasActive) {
      return "active";
    } else if (hasInactive) {
      return "inactive";
    } else {
      return "";
    }
  }

  //HANDLE USER LIST SELECTION
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedUsers(selectedRowKeys);
      //CHECK SELECTED USERS STATUS TO DETERMINE THE USER ACTION OPTIONS
      var status = checkUserStatus(selectedRows);

      if (status.toLowerCase() === "active") {
        //SET ACTIVE USER ACTION OPTIONS
        setUserActionsItems(activeUserOptions);
      } else if (status.toLowerCase() === "inactive") {
        //SET INACTIVE USER ACTION OPTIONS
        setUserActionsItems(inactiveUserOptions);
      } else if (status.toLowerCase() === "both") {
        //SET BOTH STATUS USER ACTION OPTIONS
        setUserActionsItems(activeInactiveUserOptions);
      } else {
        //SET NO USER SELECTED ACTION OPTIONS
        setUserActionsItems(noUserSelectedOptions);
      }
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  if (isLoading) {
    return (
      <Card style={{ padding: "20px" }}>
        {contextHolder}
        <Flex vertical gap="30px">
          <Flex justify="center" vertical align="center">
            <ColorRing
              visible={true}
              height="80"
              width="80"
              ariaLabel="color-ring-loading"
              wrapperStyle={{}}
              wrapperClass="color-ring-wrapper"
              colors={["#26225A", "#66618D", "#545A6D", "#BCBEC0"]}
            />
          </Flex>
        </Flex>
      </Card>
    );
  }

  if (!isAdmin) {
    return (
      <Card>
        {contextHolder}
        <Flex>
          <Flex>
            <div className="centered text-4xl h-screen">
              <span style={{fontSize: 16, fontWeight: 'bold'}}>
                You are not authorized to access this page.
              </span>
            </div>
          </Flex>
        </Flex>
      </Card>
    );
  }

  if (error) {
    return (
      <Card>
        {contextHolder}
        <Flex>
          <Flex>
            <div className="centered text-4xl h-screen">
              Something went wrong! Please try again.
            </div>
          </Flex>
        </Flex>
      </Card>
    );
  }

  return (
    <>
      <Header className="profileMenuBar">
        <CustomHeader items={[{ title: "User List", href: "/userList" }]} />
      </Header>
      <Card style={{ padding: "20px" }}>
        {contextHolder}
        <Flex vertical gap="30px">
          <Flex vertical align="flex-start">
            <div className="user-table">
              <Space
                direction="vertical"
                size="small"
                style={{ display: "flex" }}
              >
                <Flex justify="space-between" align="flex-start">
                  <Flex gap="small" justify="flex-start" align="center">
                    <Space size="small" align="center">
                      <span>Filter Users:</span>
                      <Button
                        className="confirmButton"
                        onClick={handleActiveButtonClick}
                      >
                        Active
                      </Button>
                      <Button
                        className="confirmFilterUsersInactive"
                        onClick={handleInactiveButtonClick}
                      >
                        Inactive
                      </Button>
                      <Button
                        className="confirmFilterUsersAll"
                        onClick={handleAllUsersButtonClick}
                      >
                        All
                      </Button>
                    </Space>
                  </Flex>
                  <Flex gap="small" justify="flex-end" align="center">
                    <Space size="small" align="center">
                      <Button
                        onClick={clearFilters}
                        className="confirmButton"
                        style={{ marginBottom: "8px" }}
                      >
                        Clear Filters
                      </Button>
                      <Dropdown menu={menuProps}>
                        <Button className="confirmUserActionsButton">
                          <Space>
                            User Actions <DownOutlined />
                          </Space>
                        </Button>
                      </Dropdown>
                    </Space>
                  </Flex>
                </Flex>
                <Space size="small" align="center">
                  <Select
                    mode="multiple"
                    placeholder="Title"
                    className="userListSearchBox"
                    onChange={handleTitleSearchChange}
                    options={titleOptions}
                    allowClear
                    style={{ width: "100px" }}
                    value={selectedTitleValues}
                  ></Select>
                  <Input.Search
                    placeholder="Name"
                    className="userListSearchBox"
                    onSearch={(value) => {
                      setNameSearchText(value);
                    }}
                    onChange={(e) => {
                      setNameSearchText(e.target.value);
                    }}
                    style={{ width: "150px" }}
                    value={nameSearchText}
                  />
                  <Select
                    placeholder="Account Level"
                    className="userListSearchBox"
                    onChange={handleAccountLevelSearchChange}
                    options={accountLevelOptions}
                    allowClear
                    style={{ width: "150px" }}
                    value={selectedAccountLvelValues}
                  ></Select>
                  <RangePicker
                    className="userListSearchBox"
                    value={[accountLevelStartDate, accountLevelEndDate]}
                    onChange={handleAccountLevelRangeChange}
                  ></RangePicker>
                  <Input.Search
                    placeholder="City"
                    className="userListSearchBox"
                    onSearch={(value) => {
                      setCitySearchText(value);
                    }}
                    onChange={(e) => {
                      setCitySearchText(e.target.value);
                    }}
                    value={citySearchText}
                  />
                  <Input.Search
                    placeholder="State"
                    className="userListSearchBox"
                    onSearch={(value) => {
                      setStateSearchText(value);
                    }}
                    onChange={(e) => {
                      setStateSearchText(e.target.value);
                    }}
                    value={stateSearchText}
                  />
                  <Input.Search
                    placeholder="Country"
                    className="userListSearchBox"
                    onSearch={(value) => {
                      setCountrySearchText(value);
                    }}
                    onChange={(e) => {
                      setCountrySearchText(e.target.value);
                    }}
                    value={countrySearchText}
                  />
                </Space>
              </Space>
              <Table
                rowClassName={(record, index) =>
                  record.isActive
                    ? "tableRowActiveUser"
                    : "tableRowInactiveUser"
                }
                rowSelection={{
                  type: "checkbox",
                  ...rowSelection,
                }}
                
                columns={columns}
                dataSource={users}
                rowKey={(record) => record.id}
                pagination={tableParams.pagination}
                onChange={handleTableChange}
              />
            </div>
          </Flex>
        </Flex>
      </Card>
    </>
  );
}
export default UserList;
