import { Button, Form, Input, Modal, Select, DatePicker } from "antd";
import { ModalFooter } from "../ModalFooter";
import React, { useState, useEffect } from "react";
import "react-phone-input-2/lib/style.css";
const { TextArea } = Input;

function CreateNote({
  onOpenCreateNoteModal,
  onCancelCreateNoteModal,
  onCreateNote,
  onEditNote,
  setNote,
  editNote,
  note,
}) {
  const [form] = Form.useForm();
  const [isEditMode, setIsEditMode] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setHasChanges(true);
    setNote({ ...note, [name]: value });
  };

  const cancelModal = () => {
    onCancelCreateNoteModal();
  };

  useEffect(() => {
    setIsEditMode(editNote && Object.keys(editNote).length > 0);
  }, [editNote]);

  return (
    <div className="App">
      <Modal
        style={{ color: "#26225A" }}
        title={isEditMode ? "Edit Note" : "Add Note"}
        centered
        destroyOnClose={true}
        onCancel={() => {
          form.resetFields();
          cancelModal();
        }}
        open={onOpenCreateNoteModal}
        footer={[]}
      >
        <Form 
          form={form} 
          layout="vertical" 
          preserve={false}
          initialValues={{ text: editNote?.text ? editNote.text : editNote?.note ? editNote.note : null}}
        >
          <Form.Item
            label="Note"
            name="text"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <TextArea
              style={{ height: "80px" }}
              name="text"
              onChange={onInputChange}
            />
          </Form.Item>
          <ModalFooter
            form={form}
            onCancel={() => {
              form.resetFields();
              cancelModal();
            }}
            onConfirm={isEditMode ? () => onEditNote(form.getFieldsValue()) : onCreateNote}
            submitText={isEditMode ? "Save" : "Add"}
            hasChanges={hasChanges}
          />
        </Form>
      </Modal>
    </div>
  );
}

export default CreateNote;
