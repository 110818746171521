import { Form, Input, Modal, Spin } from "antd";
import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { ModalFooter } from "../../ModalFooter";

function CreateUser({
  isLoading,
  onOpenCreateUserModal,
  onCancelCreateUserModal,
  onCreateUser,
  onInputChange,
  onPhoneNumberChange,
}) {
  const [form] = Form.useForm();

  return (
    <div className="App">
      <Modal
        style={{ color: "#26225A" }}
        title="New User"
        centered
        destroyOnClose={true}
        open={onOpenCreateUserModal}
        onOk={onCreateUser}
        onCancel={onCancelCreateUserModal}
        footer={[]}
      >
        <Spin spinning={isLoading} fullscreen size="large" />
        <Form form={form} preserve={false} name="validateOnly" layout="vertical">
          <Form.Item
            label="Email Address (Username)"
            name="email"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input
              name="email"
              placeholder=""
              onChange={onInputChange}
            />
          </Form.Item>
          <Form.Item
            label="First Name"
            name="firstName"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input
              name="firstName"
              placeholder=""
              onChange={onInputChange}
            />
          </Form.Item>
          <Form.Item
            label="Last Name"
            name="lastName"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input name="lastName" placeholder="" onChange={onInputChange} />
          </Form.Item>
          <Form.Item
            label="Mobile Number"
            name="phoneNumber"
            rules={[
              {
                required: false,
              },
            ]}
          >
            <PhoneInput
              country={"us"}
              preferredCountries={["us"]}
              countryCodeEditable={false}
              enableSearch={true}
              onChange={onPhoneNumberChange}
              inputProps={{ name: "mobileNumber", required: false }}
            />
          </Form.Item>
          <ModalFooter 
            form={form}
            onCancel={onCancelCreateUserModal}
            onConfirm={onCreateUser}
            submitText="Add User"
            hasChanges={true}
          />
        </Form>
      </Modal>
    </div>
  );
}

export default CreateUser;
