import { Form, Input, Modal, DatePicker } from "antd";
import dayjs from "dayjs";
import React, { useState, useEffect } from "react";
import { ModalFooter } from "../ModalFooter";
import "react-phone-input-2/lib/style.css";
const { TextArea } = Input;

function CreateSchedule({
  onOpenCreateScheduleModal,
  onCancelCreateScheduleModal,
  onCreateSchedule,
  onEditSchedule,
  setSchedule,
  schedule,
  editSchedule,
}) {
  const [form] = Form.useForm();
  const [isEditMode, setIsEditMode] = useState(false);
  const [hasChanges, setHasChanged] = useState(false);

  const cancelModal = () => {
    onCancelCreateScheduleModal();
  };

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setHasChanged(true);
    setSchedule({ ...schedule, [name]: value });
  };

  const onStartDateInputChange = (e) => {
    setHasChanged(true);
    setSchedule({
      ...schedule,
      startDate: e ? dayjs(e).utc('z').toISOString() : null
    });
  };
  
  const onEndDateInputChange = (e) => {
    setHasChanged(true);
    setSchedule({
      ...schedule,
      endDate: e ? dayjs(e).utc('z').toISOString() : null
    });
  };
  
  useEffect(() => {
    if (onOpenCreateScheduleModal) {
      setIsEditMode(editSchedule && Object.keys(editSchedule).length > 0);
      if (editSchedule && Object.keys(editSchedule).length > 0) {
        form.setFieldsValue({
          name: editSchedule.name,
          startDate: dayjs(editSchedule.startDate).utc(),
          endDate: dayjs(editSchedule.endDate).utc(),
          presenterName: editSchedule.presenterName,
        })
        setSchedule(editSchedule);
      }
    }
  }, [onOpenCreateScheduleModal]);

  return (
    <div className="App">
      <Modal
        style={{ color: "#26225A" }}
        title={isEditMode ? "Edit Schedule Item" : "Add Schedule Item"}
        centered
        destroyOnClose={true}
        open={onOpenCreateScheduleModal}
        onOk={isEditMode ? onEditSchedule : onCreateSchedule}
        onCancel={() => {
          form.resetFields();
          cancelModal();
        }}
        footer={[]}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            label="Schedule Item"
            rules={[
              {
                required: true,
              },
            ]}
            name="name"
          >
            <TextArea
              style={{ height: "80px" }}
              name="name"
              placeholder="Schedule Item"
              onChange={onInputChange}
            />
          </Form.Item>
          <Form.Item
            label="Start Time"
            name="startDate"
            rules={[
              {
                required: true,
                message: "Start time is required",
              },
              {
                validator: async () => {
                  const scheduleValues = form.getFieldsValue();
                  if (scheduleValues.endDate)
                    if (!(dayjs(scheduleValues.startDate).isBefore(dayjs(scheduleValues.endDate)))) {
                      return Promise.reject(
                        "Start time must be earlier than End time"
                      );
                    }
                },
              }
            ]}
          >
            <DatePicker
              showTime
              showSecond={false}
              name="startDate"
              className="userListSearchBox"
              style={{ width: "100%" }}
              onChange={onStartDateInputChange}
            />
          </Form.Item>
          <Form.Item
            label="End Time"
            name="endDate"
            rules={[
              {
                required: true,
                message: "End time is required",
              },
              {
                validator: async () => {
                  const scheduleValues = form.getFieldsValue();
                  if (scheduleValues.startDate)
                    if (!(dayjs(scheduleValues.startDate).isBefore(dayjs(scheduleValues.endDate)))) {
                      return Promise.reject(
                        "End time must be later than Start time"
                      );
                    }
                },
              }
            ]}
          >
            <DatePicker
              showTime
              showSecond={false}
              name="endDate"
              className="userListSearchBox"
              style={{ width: "100%" }}
              onChange={onEndDateInputChange}
            />
          </Form.Item>
          <Form.Item
            label="Presenters"
            name="presenterName"
          >
            <Input
              name="presenterName"
              placeholder="Presenters"
              onChange={onInputChange}
            />
          </Form.Item>
          <ModalFooter
            form={form}
            onCancel={() => {
              form.resetFields();
              cancelModal();
            }}
            onConfirm={
              isEditMode
                ? onEditSchedule
                : onCreateSchedule
            }
            submitText={isEditMode ? "Save" : "Add"}
            hasChanges={hasChanges}
          />
        </Form>
      </Modal>
    </div>
  );
}

export default CreateSchedule;
