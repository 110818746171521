import { Card, Flex, Tabs, Modal } from "antd";
import React, { useEffect, useState } from "react";
import AccountDetails from "./AccountDetails";
import PersonalInformation from "./PersonalInformation";
import Membership from "./Membership";
import ShippingDetails from "./ShippingDetails";
import UserNotes from "./UserNotes";
import { MdAccountCircle, MdCardMembership } from "react-icons/md";
import { CgDetailsMore } from "react-icons/cg";
import { useLocation } from "react-router-dom";
import { ImAddressBook } from "react-icons/im";
import { useMsal } from "@azure/msal-react";
import { Header } from "antd/es/layout/layout";
import ProfileHeader from "../../components/ProfileHeader";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { ColorRing } from "react-loader-spinner";
import UserService from "../../services/UserService";
import FetchClient from "../../serviceClients/FetchClient";

function ProfileTabs() {
  const { instance } = useMsal();
  const { confirm } = Modal;
  const location = useLocation();
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [defaultTab, setDefaultTab] = useState("1");
  const [facilitatorData, setFacilitatorData] = useState(JSON.parse(localStorage.getItem("facilitatorUser")));
  const [userData, setUserData] = useState({});
  const [isSameAdminUser, setIsSameAdminUser] = useState(false);
  const [loggedUser, setLoggedUser] = useState({});
  let activeAccount;

  useEffect(() => {
    activeAccount = instance.getActiveAccount();
    setLoggedUser(JSON.parse(localStorage.getItem("loggedUser")));
  }, []);

  useEffect(() => {
    let loggedUser = JSON.parse(localStorage.getItem("loggedUser"));
    if (location.pathname === "/profile/edit" && location?.state && Object.keys(location?.state?.userData).length > 0) {
      setUserData(location.state.userData);
      if (location.state.userData.email === loggedUser.email)
        setIsSameAdminUser(true);
      else
        setIsSameAdminUser(false);
        setIsLoading(false);
    } 
    else if (facilitatorData && Object.keys(facilitatorData).length > 0) {
      setUserData(facilitatorData);
      if (facilitatorData.email === loggedUser.email)
        setIsSameAdminUser(true);
      else
        setIsSameAdminUser(false);
      localStorage.removeItem("facilitatorUser");
      setIsLoading(false);
    } else {
      //GET USER DATA FROM LOGGED IN ACCOUNT
      let usersData
      if (!loggedUser) {
        getLoggedUser().then((res) => {
          setLoggedUser(res);
          loggedUser = res;
          usersData = {
            ...loggedUser,
            email: activeAccount?.username,
          };
          setUserData(usersData)
          setIsSameAdminUser(true);
          setIsLoading(false);
        });
      } else {
        usersData = {
          ...loggedUser,
          email: activeAccount?.username,
        };
        setUserData(usersData)
        setIsSameAdminUser(true);
        setIsLoading(false);
      }
    }
  }, [location, facilitatorData]);

  const getLoggedUser = async () => {
    try {
      const _fetchInstance = new FetchClient(activeAccount.idToken);
      const _userService = new UserService(_fetchInstance);
      let logUser = await _userService.getUserByEmail(
        activeAccount.idTokenClaims.emails[0]
      );
      if (logUser) {
        localStorage.setItem("loggedUser", JSON.stringify(logUser));
        return logUser;
      }
      return null;
    } catch (error) {
      console.log(error);
      if (error.code === 401) {
        localStorage.removeItem("loggedUser");
        await instance.logout({
          onRedirectNavigate: () => {
            return false;
          }
        });
        await instance.loginRedirect();
      }
    }
  }

  
  //HANDLE TAB CHANGE
  const onChange = (key) => {
    if (!isSaveDisabled) {
      showConfirm(key);
      return;
    } else {
      setDefaultTab(key);
    }
  };

  //CONFIRM MODAL
  const showConfirm = (key) => {
    confirm({
      className: "confirmModalStyle",
      centered: true,
      icon: <ExclamationCircleFilled />,
      content: "Do you want to proceed without saving changes?",
      okText: "Yes",
      cancelText: "No",
      onOk() {
        setIsSaveDisabled(true);
        setDefaultTab(key);
      },
      onCancel() {
        console.log("Cancel");
      },
      width: 500,
    });
  };
  //TABS
  const items = [
    {
      key: "1",
      label: "Account Details",
      children: (
        <AccountDetails
          userData={userData}
          isSaveDisabled={isSaveDisabled}
          setIsSaveDisabled={setIsSaveDisabled}
          setUserData={setUserData}
          loggedUser={loggedUser}
          isSameAdminUser={isSameAdminUser}
        />
      ),
      icon: <CgDetailsMore />,
    },
    {
      key: "2",
      label: "Personal Information",
      children: (
        <PersonalInformation
          userData={userData}
          isSaveDisabled={isSaveDisabled}
          setIsSaveDisabled={setIsSaveDisabled}
          loggedUser={loggedUser}
          isSameAdminUser={isSameAdminUser}
        />
      ),
      icon: <MdAccountCircle />,
    },
    {
      key: "3",
      label: "Membership",
      children: (
        <Membership
          userData={userData}
          setUserData={setUserData}
          isSaveDisabled={isSaveDisabled}
          setIsSaveDisabled={setIsSaveDisabled}
          loggedUser={loggedUser}
          isSameAdminUser={isSameAdminUser}
        />
      ),
      icon: <MdCardMembership />,
    },
    {
      key: "4",
      label: "Address",
      children: (
        <ShippingDetails
          userData={userData}
          setUserData={setUserData}
          isSaveDisabled={isSaveDisabled}
          setIsSaveDisabled={setIsSaveDisabled}
          loggedUser={loggedUser}
          isSameAdminUser={isSameAdminUser}
        />
      ),
      icon: <ImAddressBook />,
    },
  ];

  if (isLoading) {
    return (
      <Card style={{ padding: "20px" }}>
        <Flex vertical gap="30px">
          <Flex justify="center" vertical align="center">
            <ColorRing
              visible={true}
              height="80"
              width="80"
              ariaLabel="color-ring-loading"
              wrapperStyle={{}}
              wrapperClass="color-ring-wrapper"
              colors={["#26225A", "#66618D", "#545A6D", "#BCBEC0"]}
            />
          </Flex>
        </Flex>
      </Card>
    );
  }

  if (!isSameAdminUser && loggedUser?.role !== "admin") {
    return (
      <Card>
        <Flex>
          <Flex>
            <div className="centered text-4xl h-screen">
              <span style={{fontSize: 16, fontWeight: 'bold'}}>
                You are not authorized to access this page.
              </span>
            </div>
          </Flex>
        </Flex>
      </Card>
    );
  }

  return (
    <>
      <Header className="profileMenuBar">
        <ProfileHeader 
          userData={userData} 
          isSameAdminUser={isSameAdminUser} 
        />
      </Header>
      <Tabs
        items={items}
        onChange={onChange}
        activeKey={defaultTab}
        defaultActiveKey={defaultTab}
        destroyInactiveTabPane={true}
      ></Tabs>
      {!isSameAdminUser && loggedUser?.role === "admin" && (
        <Card>
          <UserNotes userData={userData} />
        </Card>
      )}
    </>
  );
}

export default ProfileTabs;
