import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import CourseService from "../../services/CourseService";
import FetchClient from "../../serviceClients/FetchClient";
import {
  Card,
  Flex,
  Space,
  Table,
  DatePicker,
  Button,
  message,
} from "antd";
import { Header } from "antd/es/layout/layout";
import CustomHeader from "../../components/Header";
import { PencilSquare } from "react-bootstrap-icons";
import { ColorRing } from "react-loader-spinner";
import { useMsal } from "@azure/msal-react";
import CreateCourse from "../../components/Settings/Course/CreateCourse";

function CourseList() {
  const { instance } = useMsal();
  const { confirm } = Modal;
  const { RangePicker } = DatePicker;
  const [messageApi, contextHolder] = message.useMessage();
  const [course, setCourse] = useState({});
  const [editCourse, setEditCourse] = useState({});
  const [courses, setCourses] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [createCourseModal, setCreateCourseModal] = useState(false);
  const [error, setError] = useState();
  const [tableParams, setTableParams] = useState({
    pagination: {
      position: ["bottomRight"],
      pageSizeOptions: [10, 20, 50, 100],
    },
  });
  const [selectedCourses, setSelectedCourses] = useState([]);

  let activeAccount;
  if (instance) {
    activeAccount = instance.getActiveAccount();
  }

  //GET COURSE LIST
  async function fetchCourses() {
    setIsLoading(true);

    const _fetchInstance = new FetchClient(activeAccount.idToken);
    //CREATE COURSE SERVICE INSTANCE
    const courseService = new CourseService(_fetchInstance);

    try {
      const courseData = await courseService.getAllCourses();
      setCourses(courseData);
      setTableParams({
        ...tableParams,
        pagination: { ...tableParams.pagination },
      });
    } catch (error) {
      setError(error);
      if (error.code === 401) {
        localStorage.removeItem("loggedUser");
        await instance.logout({
          onRedirectNavigate: () => {
            return false;
          }
        });
        await instance.loginRedirect();
      }
    } finally {
      setIsLoading(false);
    }
  }

  function showDeleteModal() {
    showConfirm();
  }

  async function deleteCourse() {
    setIsLoading(true);
    const _fetchInstance = new FetchClient(activeAccount.idToken);
    const _courseService = new CourseService(_fetchInstance);

    try {
      //DELETE USER
      for (const course of selectedCourses) {
        await _courseService.deleteCourse(course);
      }
      messageApi.open({
        type: "success",
        content: "Course deleted successfully!",
        className: "custom-class",
        duration: "5",
        style: {
          marginTop: "20vh",
        },
      });
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      messageApi.open({
        type: "error",
        content: "Something went wrong. Please try again later.",
        className: "custom-class",
        duration: "5",
        style: {
          marginTop: "20vh",
        },
      });

      if (error.code === 401) {
        localStorage.removeItem("loggedUser");
        await instance.logout({
          onRedirectNavigate: () => {
            return false;
          }
        });
        await instance.loginRedirect();
      }
    } finally {
      setSelectedCourses([]);
      setIsLoading(false);
      fetchCourses();
    }
  }

  useEffect(() => {
    const loggedUser = JSON.parse(localStorage.getItem("loggedUser"));
    if (loggedUser.role === "admin") {
      setIsAdmin(true);
      fetchCourses();
    } else {
      setIsAdmin(false);
    }
  }, [
    activeAccount.idToken,
    tableParams.pagination?.current,
    tableParams.pagination?.pageSize,
  ]);

  //HANDLE TABLE CHANGE
  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({ pagination, filters, ...sorter });

    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setCourses([]);
    }
  };

  //HANDLE EDIT COURSE BUTTON CLICK
  const handleEditClick = (record) => {
    setEditCourse(record);
    setCreateCourseModal(true);
  };

  const onCloseCourseCreateModal = () => {
    setEditCourse({});
    setCourse({});
    setCreateCourseModal(false);
  };

  const onCreateCourse = async () => {
    setIsLoading(true);
    setEditCourse({});
    setCourse({});
    const _fetchInstance = new FetchClient(activeAccount.idToken);
    const _courseService = new CourseService(_fetchInstance);
    let createdCourse = {};

    try {
      //CREATE USER
      createdCourse = await _courseService.createCourse(course);
      messageApi.open({
        type: "success",
        content: "Course created successfully!",
        className: "custom-class",
        duration: "5",
        style: {
          marginTop: "20vh",
        },
      });
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      messageApi.open({
        type: "error",
        content: "Something went wrong. Please try again later.",
        className: "custom-class",
        duration: "5",
        style: {
          marginTop: "20vh",
        },
      });

      if (error.code === 401) {
        localStorage.removeItem("loggedUser");
        await instance.logout({
          onRedirectNavigate: () => {
            return false;
          }
        });
        await instance.loginRedirect();
      }
    } finally {
      onCloseCourseCreateModal();
      setIsLoading(false);
      fetchCourses();
    }
  };
  const onEditCourse = async () => {
    setIsLoading(true);
    const _fetchInstance = new FetchClient(activeAccount.idToken);
    const _courseService = new CourseService(_fetchInstance);
    let createdCourse = {};

    try {
      //CREATE USER
      createdCourse = await _courseService.updateCourse(course);
      messageApi.open({
        type: "success",
        content: "Course updated successfully!",
        className: "custom-class",
        duration: "5",
        style: {
          marginTop: "20vh",
        },
      });
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      messageApi.open({
        type: "error",
        content: "Something went wrong. Please try again later.",
        className: "custom-class",
        duration: "5",
        style: {
          marginTop: "20vh",
        },
      });

      if (error.code === 401) {
        localStorage.removeItem("loggedUser");
        await instance.logout({
          onRedirectNavigate: () => {
            return false;
          }
        });
        await instance.loginRedirect();
      }
    } finally {
      onCloseCourseCreateModal();
      setIsLoading(false);
      fetchCourses();
    }
  };

  const columns = [
    {
      title: "Course Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
      sorter: (a, b) => a.code.localeCompare(b.code),
    },
    {
      title: "Order",
      dataIndex: "orderValue",
      key: "orderValue",
      sorter: (a, b) => a.orderValue - b.orderValue,
    },
    {
      title: "Edit",
      key: "edit",
      render: (_, record) => (
        <Space size="middle">
          <PencilSquare
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleEditClick(record);
            }}
          />
        </Space>
      ),
    },
  ];

  //CONFIRM MODAL
  const showConfirm = (key) => {
    confirm({
      className: "confirmModalStyle",
      centered: true,
      icon: <ExclamationCircleFilled />,
      content: "Do you want to delete this course?",
      okText: "Yes",
      cancelText: "No",
      onOk() {
        deleteCourse();
      },
      onCancel() {
        console.log("Cancel");
      },
      width: 500,
    });
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedCourses(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled Course",
      name: record.name,
    }),
  };

  if (isLoading) {
    return (
      <Card style={{ padding: "20px" }}>
        {contextHolder}
        <Flex vertical gap="30px">
          <Flex justify="center" vertical align="center">
            <ColorRing
              visible={true}
              height="80"
              width="80"
              ariaLabel="color-ring-loading"
              wrapperStyle={{}}
              wrapperClass="color-ring-wrapper"
              colors={["#26225A", "#66618D", "#545A6D", "#BCBEC0"]}
            />
          </Flex>
        </Flex>
      </Card>
    );
  }

  if (!isAdmin) {
    return (
      <Card>
        {contextHolder}
        <Flex>
          <Flex>
            <div className="centered text-4xl h-screen">
              <span style={{fontSize: 16, fontWeight: 'bold'}}>
                You are not authorized to access this page.
              </span>
            </div>
          </Flex>
        </Flex>
      </Card>
    );
  }

  if (error) {
    return (
      <Card>
        {contextHolder}
        <Flex>
          <Flex>
            <div className="centered text-4xl h-screen">
              Something went wrong! Please try again.
            </div>
          </Flex>
        </Flex>
      </Card>
    );
  }

  return (
    <>
      <Header className="profileMenuBar">
        <CustomHeader
          items={[
            { title: "Settings" },
            { title: "Courses" },
          ]}
        />
      </Header>
      <Card style={{ padding: "20px" }}>
        <CreateCourse
          onOpenCreateCourseModal={createCourseModal}
          onCancelCreateCourseModal={onCloseCourseCreateModal}
          onEditCourse={onEditCourse}
          onCreateCourse={onCreateCourse}
          setCourse={setCourse}
          course={course}
          allCourses={courses}
          editCourse={editCourse}
        />

        {contextHolder}
        <Flex vertical gap="30px">
          <Flex vertical align="flex-start">
            <div className="course-table">
              <Space
                size="small"
                style={{ display: "flex", justifyContent: "flex-end", alignItems: "flex-end"}}
              >
                  <Button
                    onClick={() => setCreateCourseModal(true)}
                    className="confirmButton"
                    style={{ marginBottom: "8px" }}
                    >
                    Add Course +
                  </Button>
                  {selectedCourses.length > 0 && (
                    <Button
                      onClick={showDeleteModal}
                      className="confirmButton"
                      style={{ marginBottom: "8px" }}
                    >
                      Delete
                    </Button>
                  )}
              </Space>
              <Table
                rowClassName={"tableRowActiveCourse"}
                rowSelection={{
                  type: "checkbox",
                  ...rowSelection,
                }}
                columns={columns}
                dataSource={courses}
                rowKey={(record) => record.id}
                pagination={false}
                onChange={handleTableChange}
              />
            </div>
          </Flex>
        </Flex>
      </Card>
    </>
  );
}
export default CourseList;
