import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import dayjs from "dayjs";
import { ExclamationCircleFilled } from "@ant-design/icons";
import NoteService from "../../../services/NoteService";
import FetchClient from "../../../serviceClients/FetchClient";
import {
  Card,
  Flex,
  Input,
  Space,
  Table,
  Button,
  message,
  Row,
  Col,
} from "antd";
import { PencilSquare } from "react-bootstrap-icons";
import { ColorRing } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import CreateNote from "../../../components/ClassesEvents/CreateNote";

function Notes({ setActiveTab, eventData }) {
  const { instance } = useMsal();
  const { confirm } = Modal;
  const [messageApi, contextHolder] = message.useMessage();
  const [note, setNote] = useState({});
  const [notes, setNotes] = useState([]);
  const [allNotes, setAllNotes] = useState([]);
  const [editNote, setEditNote] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [createNoteModal, setCreateNoteModal] = useState(false);
  const [error, setError] = useState();
  const [selectedNotes, setSelectedNotes] = useState([]);
  let activeAccount;
  if (instance) {
    activeAccount = instance.getActiveAccount();
  }

  //GET COURSE LIST
  async function fetchNotes() {
    // setIsLoading(true);

    const _fetchInstance = new FetchClient(activeAccount.idToken);
    //CREATE COURSE SERVICE INSTANCE
    const noteService = new NoteService(_fetchInstance);

    try {
      let eventId = localStorage.getItem("eventId");
      const noteData = await noteService.getAllNotes(eventId);
      setNotes(noteData);
      setAllNotes(noteData);
    } catch (error) {
      setError(error);
      if (error.code === 401) {
        localStorage.removeItem("loggedUser");
        await instance.logout({
          onRedirectNavigate: () => {
            return false;
          }
        });
        await instance.loginRedirect();
      }
    } finally {
      setIsLoading(false);
    }
  }

  function showDeleteModal() {
    showConfirm();
  }

  async function deleteNote() {
    setIsLoading(true);
    const _fetchInstance = new FetchClient(activeAccount.idToken);
    const _noteService = new NoteService(_fetchInstance);
    
    try {
      //DELETE USER
      for (const note of selectedNotes) {
        await _noteService.deleteNote(note);
      }
      messageApi.open({
        type: "success",
        content: "Note deleted successfully!",
        className: "custom-class",
        duration: "5",
        style: {
          marginTop: "20vh",
        },
      });
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      messageApi.open({
        type: "error",
        content: "Something went wrong. Please try again later.",
        className: "custom-class",
        duration: "5",
        style: {
          marginTop: "20vh",
        },
      });

      if (error.code === 401) {
        localStorage.removeItem("loggedUser");
        await instance.logout({
          onRedirectNavigate: () => {
            return false;
          }
        });
        await instance.loginRedirect();
      }
    } finally {
      setSelectedNotes([]);
      setIsLoading(false);
      fetchNotes();
    }
  }

  useEffect(() => {
    fetchNotes();
  }, [
    activeAccount.idToken,
  ]);

  //HANDLE EDIT COURSE BUTTON CLICK
  const handleEditClick = (record) => {
    setEditNote(record);
    setCreateNoteModal(true);
  };

  //SET COURSE LIST TABLE COLUMNS
  const columns = [
    {
      title: "Note",
      dataIndex: "note",
      key: "note",
      sorter: (a, b) => a.note.localeCompare(b.note),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: (a, b) => a.status.localeCompare(b.status),
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy",
      sorter: (a, b) => a.createdBy.localeCompare(b.createdBy),
    },
    {
      title: "Date Created",
      dataIndex: "creationDate",
      key: "creationDate",
      sorter: (a, b) => a.creationDate.localeCompare(b.creationDate),
      render: (_, record) => (
        <>
          <span>{dayjs(record.creationDate).utc().format('MM/DD/YYYY')}</span>
        </>
      ),
    },
    {
      title: "",
      key: "edit",
      render: (_, record) => (
        <Space size="middle">
          <PencilSquare
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleEditClick(record);
            }}
          />
        </Space>
      ),
    },
  ];

  //CONFIRM MODAL
  const showConfirm = (key) => {
    confirm({
      className: "confirmModalStyle",
      centered: true,
      icon: <ExclamationCircleFilled />,
      content: "Do you want to delete this note?",
      okText: "Yes",
      cancelText: "No",
      onOk() {
        console.log("ok");
        deleteNote();
      },
      onCancel() {
        console.log("Cancel");
      },
      width: 500,
    });
  };

  const onCloseNoteCreateModal = () => {
    setEditNote({});
    setNote({});
    setCreateNoteModal(false);
  };

  const onCreateNote = async (values) => {
    
    setIsLoading(true);
    setEditNote({});
    setNote({});
    const _fetchInstance = new FetchClient(activeAccount.idToken);
    const _noteService = new NoteService(_fetchInstance);
    let createdNote = {};

    try {
      let eventId = localStorage.getItem("eventId");
      let loggedUser = JSON.parse(localStorage.getItem("loggedUser"));
      createdNote = await _noteService.createNote({
        eventId: eventId,
        note: note.text,
        createdBy: `${loggedUser.firstName} ${loggedUser.lastName}`,
        status: eventData.status,
      });
      messageApi.open({
        type: "success",
        content: "Note created successfully!",
        className: "custom-class",
        duration: "5",
        style: {
          marginTop: "20vh",
        },
      });
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      messageApi.open({
        type: "error",
        content: "Something went wrong. Please try again later.",
        className: "custom-class",
        duration: "5",
        style: {
          marginTop: "20vh",
        },
      });

      if (error.code === 401) {
        localStorage.removeItem("loggedUser");
        await instance.logout({
          onRedirectNavigate: () => {
            return false;
          }
        });
        await instance.loginRedirect();
      }
    } finally {
      onCloseNoteCreateModal();
      setIsLoading(false);
      fetchNotes();
    }
  };
  const onEditNote = async (values) => {
    setIsLoading(true);
    const _fetchInstance = new FetchClient(activeAccount.idToken);
    const _noteService = new NoteService(_fetchInstance);
    let createdNote = {};

    try {
      //CREATE USER
      createdNote = await _noteService.updateNote({...editNote, ...values});
      messageApi.open({
        type: "success",
        content: "Note updated successfully!",
        className: "custom-class",
        duration: "5",
        style: {
          marginTop: "20vh",
        },
      });
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      messageApi.open({
        type: "error",
        content: "Something went wrong. Please try again later.",
        className: "custom-class",
        duration: "5",
        style: {
          marginTop: "20vh",
        },
      });

      if (error.code === 401) {
        localStorage.removeItem("loggedUser");
        await instance.logout({
          onRedirectNavigate: () => {
            return false;
          }
        });
        await instance.loginRedirect();
      }
    } finally {
      onCloseNoteCreateModal();
      setIsLoading(false);
      fetchNotes();
    }
  };

  const rowSelection = {
    hideSelectAll : true,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedNotes(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled Note",
      name: record.name,
    }),
  };

  if (isLoading) {
    return (
      <Card style={{ padding: "20px" }}>
        {contextHolder}
        <Flex vertical gap="30px">
          <Flex justify="center" vertical align="center">
            <ColorRing
              visible={true}
              height="80"
              width="80"
              ariaLabel="color-ring-loading"
              wrapperStyle={{}}
              wrapperClass="color-ring-wrapper"
              colors={["#26225A", "#66618D", "#545A6D", "#BCBEC0"]}
            />
          </Flex>
        </Flex>
      </Card>
    );
  }

  if (error) {
    return (
      <Card>
        {contextHolder}
        <Flex>
          <Flex>
            <div className="centered text-4xl h-screen">
              Something went wrong! Please try again.
            </div>
          </Flex>
        </Flex>
      </Card>
    );
  }

  return (
    <>
      <Card style={{ padding: "20px" }}>
        {contextHolder}
        <Flex vertical gap="30px">
          <Flex vertical align="flex-start">
            <div className="course-table">
              <Space
                direction="vertical"
                size="small"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                }}
              >
                <Button
                  onClick={() => setCreateNoteModal(true)}
                  className="confirmButton"
                  style={{ marginBottom: "8px" }}
                >
                  Add Note +
                </Button>
                {selectedNotes.length > 0 && (
                  <Button
                    onClick={showDeleteModal}
                    className="confirmButton"
                    style={{ marginBottom: "8px" }}
                  >
                    Delete
                  </Button>
                )}
              </Space>
              <Table
                rowClassName={"tableRowActiveNote"}
                rowSelection={{
                  type: "checkbox",
                  ...rowSelection,
                }}
                columns={columns}
                dataSource={notes}
                rowKey={(record) => record.id}
                pagination={false}
              />
              <CreateNote
                onOpenCreateNoteModal={createNoteModal}
                onCancelCreateNoteModal={onCloseNoteCreateModal}
                onEditNote={onEditNote}
                onCreateNote={onCreateNote}
                setNote={setNote}
                note={note}
                allNotes={notes}
                editNote={editNote}
              />
            </div>
          </Flex>
        </Flex>
        <Row style={{ marginTop: "20px" }}  gutter={24}>
          <Col span={12}>
            <div style={{ textAlign: "left" }}>
              <Button 
                onClick={() => setActiveTab(4)} 
                className="confirmButton">
                Back
              </Button>
            </div>
          </Col>
          <Col span={12}>
            <div style={{ textAlign: "right" }}>
              <Button
                onClick={() => setActiveTab(6)}
                htmlType="submit"
                className="confirmButton"
              >
                Next
              </Button>
            </div>
          </Col>
        </Row>
      </Card>
    </>
  );
}
export default Notes;
