import React from "react";
import Form from "antd/es/form/Form";
import { Button } from "antd";
export const SubmitButton = ({ form, children, hasChanges, onClickAction }) => {
  const [submittable, setSubmittable] = React.useState(false);

  // Watch all values
  const values = Form.useWatch([], form);
  React.useEffect(() => {
    form
      .validateFields({
        validateOnly: true,
      })
      .then(() => {
        if (hasChanges)
          setSubmittable(true)
        else
          setSubmittable(false)
      })
      .catch(() => setSubmittable(false));
  }, [form, values]);
  return (
    <Button
      size="middle"
      htmlType="submit"
      disabled={!submittable}
      style={{ borderColor: submittable ? "#26225A" : "", color: submittable ? "#26225A" : "" }}
      onClick={onClickAction}
    >
      {children}
    </Button>
  );
};
