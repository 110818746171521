import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Radio,
  Row,
  Select,
  Spin,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import {
  familyStatusOptions,
  prospectSourceOptions,
  roleOptions,
} from "../../shared/selectOptions";
import { useMsal } from "@azure/msal-react";
import UserService from "../../services/UserService";
import FetchClient from "../../serviceClients/FetchClient";
import MembersList from "../../components/FamilyMembers/MembersList";
import dayjs from "dayjs";
import Dues from "../../components/Profile/Dues";
import { useNavigate } from "react-router-dom";

function Membership({
  userData,
  setUserData,
  isSaveDisabled,
  setIsSaveDisabled,
  loggedUser,
  isSameAdminUser
}) {
  const navigate = useNavigate();
  const { instance } = useMsal();
  const [isLoading, setIsLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [membershipStatus, setMembershipStatus] = useState("Non-Member");
  const [isLifeTimeMember, setIsLifetimeMember] = useState(userData.isLifeTimeMember);
  const [deceased, setDeceased] = useState(null);
  const [deceasedDateVisible, setDeceasedDateVisible] = useState("none");
  const [role, setRole] = useState("user");
  const [familyStatus, setFamilyStatus] = useState(null);
  const [receivePhysicalMail, setReceivePhysicalMail] = useState(null);
  const [receiveDigitalMail, setReceiveDigitalMail] = useState(null);
  const [familyMembers, setFamilyMembers] = useState(userData.familyMembers);
  const [prospectSource, setProspectSource] = useState(null);

  //STATE VARIABLES FOR PERMISSION VALIDATIONS
  const [membershipStatusEnabled, setMembershipStatusEnabled] = useState(true);
  const [roleEnabled, setRoleEnabled] = useState("block");
  const [lifetimeMemberEnabled, setLifetimeMemberEnabled] = useState(true);
  const [deceasedDateEnabled, setDeceasedDateEnabled] = useState(true);
  const [familyStatusEnabled, setFamilyStatusEnabled] = useState(true);
  const [receiveMailEnabled, setReceiveMailEnabled] = useState(true);
  const [prospectSourceEnabled, setProspectSourceEnabled] = useState("block");
  const [familyMembersEnabled, setFamilyMembersEnabled] = useState(true);
  const [activeAccount, setActiveAccount] = useState({});

  useEffect(() => {
    if (Object.keys(userData).length > 0) {
            setDeceased(userData.deceased ? userData.deceased : false);
      setIsLifetimeMember(userData.isLifeTimeMember ? userData.isLifeTimeMember : false);
      setReceivePhysicalMail(userData.receivePhysicalMail ? userData.receivePhysicalMail : false);
      setReceiveDigitalMail(userData.receiveDigitalMail ? userData.receiveDigitalMail : false);
      setDeceasedDateVisible(userData.deceased == true ? "block" : "none");
      let memberStatus = determineMembershipStatus(userData);
      setMembershipStatus(memberStatus);
      if (userData.familyStatus == "Head of Household") {
        setFamilyMembersEnabled(true);
      } else {
        setFamilyMembersEnabled(false);
      }
    }
  }, [userData]);
  
  useEffect(() => {
    if (instance) {
      setActiveAccount(instance.getActiveAccount())
      //CHECK USER ROLE FOR PERMISSIONS
      if (isSameAdminUser) {
        setMembershipStatusEnabled(false);
        setRoleEnabled("none");
        setLifetimeMemberEnabled(false);
        setDeceasedDateEnabled(false);
        setFamilyStatusEnabled(false);
        setReceiveMailEnabled(true);
        setProspectSourceEnabled("none");
      } else if (loggedUser.role.toLowerCase() === "admin") {
        setMembershipStatusEnabled(true);
        setRoleEnabled("block");
        setLifetimeMemberEnabled(true);
        setDeceasedDateEnabled(true);
        setFamilyStatusEnabled(true);
        setReceiveMailEnabled(true);
        setProspectSourceEnabled("block");
      } else if (loggedUser.role.toLowerCase() === "user") {
        setMembershipStatusEnabled(false);
        setRoleEnabled("none");
        setLifetimeMemberEnabled(false);
        setDeceasedDateEnabled(false);
        setFamilyStatusEnabled(false);
        setReceiveMailEnabled(true);
        setProspectSourceEnabled("none");
      } else if (loggedUser.role.toLowerCase() === "teachingcoordinator") {
        setMembershipStatusEnabled(false);
        setRoleEnabled("none");
        setLifetimeMemberEnabled(false);
        setDeceasedDateEnabled(false);
        setFamilyStatusEnabled(false);
        setReceiveMailEnabled(true);
        setProspectSourceEnabled("none");
      } else if (loggedUser.role.toLowerCase() === "clubadmin") {
        setMembershipStatusEnabled(false);
        setRoleEnabled("none");
        setLifetimeMemberEnabled(false);
        setDeceasedDateEnabled(false);
        setFamilyStatusEnabled(false);
        setReceiveMailEnabled(true);
        setProspectSourceEnabled("none");
      }
    }
  }, [instance, loggedUser?.role]);

  const onMembershipChange = (value) => {
    setMembershipStatus(value);
    setIsSaveDisabled(false);
  };

  const onLifetimeChange = (e) => {
    setIsLifetimeMember(e.target.value);
    setIsSaveDisabled(false);
  };

  const onDeceasedChange = (e) => {
    setDeceased(e.target.value);
    setIsSaveDisabled(false);

    if (e.target.value) {
      setDeceasedDateVisible("block");
    } else {
      setDeceasedDateVisible("none");
      form.setFieldValue("deceasedDate", null);
    }
  };

  const onRoleChange = (value) => {
    setRole(value);
    setIsSaveDisabled(false);
  };

  const onFamilyStatusChange = (value) => {
    setFamilyStatus(value);
    setIsSaveDisabled(false);
  };

  const onReceiveMailChange = (e) => {
    setReceivePhysicalMail(e.target.value);
    form.setFieldsValue({ billingState: e.target.value });
    setIsSaveDisabled(false);
  };

  const onReceiveDigitalMailChange = (e) => {
    setReceiveDigitalMail(e.target.value);
    setIsSaveDisabled(false);
  };

  const onProspectSourceChange = (value) => {
    setProspectSource(value);
    setIsSaveDisabled(false);
  };

  const onChangeDeceasedDateChange = (date, dateString) => {
    // setDeceasedDate(dateString);
    setIsSaveDisabled(false);
  };

  const onFinish = async (values) => {
    setIsLoading(true);
    //BUILD USER OBJECT
    let deceasedDateCheck = null;
    console.log("userData: ", userData);
    if (values.deceasedDate != null && values.deceasedDate != undefined && values.deceasedDate != "") {
      deceasedDateCheck = dayjs(values.deceasedDate).utc('z').toISOString();
    }
    else {
      deceasedDateCheck = null;
    }
    
    const updateUser = {
      id: userData.id,
      email: userData.email,
      familyStatus: values.familyStatus,
      isLifeTimeMember: values.isLifeTimeMember,
      deceased: values.deceased,
      deceasedDate: deceasedDateCheck,
      //deceasedDate: dayjs(values.deceasedDate).utc('z').toISOString(),
      prospectSource: values.prospectSource,
      receivePhysicalMail: values.receivePhysicalMail,
      receiveDigitalMail: values.receiveDigitalMail,
      role: values.role,
    };
    console.log(prospectSource);
    //CREATE USER SERVICE INSTANCE
    const _fetchClient = new FetchClient(activeAccount.idToken);
    const _userService = new UserService(_fetchClient);

    try {
      //Call Service to update user data
      const resultUser = await _userService.updateUser(updateUser);
      navigate(".", { state: { userData: resultUser } });

      //DISPLAY SUCCESS NOTIFICATION MESSAGE
      messageApi.open({
        type: "success",
        content: "User profile updated successfully!",
        className: "custom-class",
        duration: "5",
        style: {
          marginTop: "20vh",
        },
      });

      setIsSaveDisabled(true);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      messageApi.open({
        type: "error",
        content: "Something went wrong. Please try again later.",
        className: "custom-class",
        duration: "5",
        style: {
          marginTop: "20vh",
        },
      });
      setIsLoading(false);

      if (error.code === 401) {
        localStorage.removeItem("loggedUser");
        await instance.logout({
          onRedirectNavigate: () => {
            return false;
          }
        });
        await instance.loginRedirect();
      }
    }
  };
  const onFinishDues = async (resultUser) => {
    setUserData(resultUser);
    setMembershipStatus(determineMembershipStatus(resultUser));
    form.setFieldValue("membershipStatus", await determineMembershipStatus(resultUser));
  };

  const onFinishMembersList = async (newUser) => {
    setUserData(newUser);
    setFamilyMembers(newUser.familyMembers);
  };

  function determineMembershipStatus(teamMember) {
    const currentDate = new Date();
    let status = 'Non-Member'; // Default status

    // Check if there are any dues
    if (teamMember.dues && teamMember.dues.length > 0) {
        let hasValidMembership = false;
        let hasExpiredMembership = false;
        let hasLifetimeMembership = false;

        for (const due of teamMember.dues) {
            const expiryDate = new Date(due.expiryDate); // Assuming each due has an expiryDate property
            
            // Check for Lifetime Membership
            if (due.type === 'Lifetime') {
                hasLifetimeMembership = true;
                break; // No need to check further if Lifetime Membership exists
            }
            
            // Check for valid or expired membership
            if (expiryDate >= currentDate) {
                hasValidMembership = true;
            } else if (expiryDate < currentDate) {
                hasExpiredMembership = true;
            }
        }

        // Determine status based on the checks
        if (hasLifetimeMembership) {
            status = 'Member';
        } else if (hasValidMembership) {
            status = 'Member';
        } else if (hasExpiredMembership) {
            status = 'Expired';
        }
    }

    return status;
  }

  const [form] = Form.useForm();

  return (
    <Card style={{ padding: "20px" }} bordered={false}>
      {contextHolder}
      <Spin spinning={isLoading} fullscreen size="large" />
      <Form
        size="large"
        form={form}
        name="membershipDetailsForm"
        layout="vertical"
        onFinish={onFinish}
        initialValues={{
          // membershipStatus: determineMembershipStatus(userData),
          role: userData.role,
          isLifeTimeMember: userData.isLifeTimeMember,
          receivePhysicalMail: userData.receivePhysicalMail,
          receiveDigitalMail: userData.receiveDigitalMail,
          familyStatus: userData.familyStatus,
          deceased: userData.deceased,
          prospectSource: userData.prospectSource,
          deceasedDate: userData.deceasedDate
            ? dayjs(userData.deceasedDate).utc()
            : null,
        }}
      >
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label="Membership Status"
              name="membershipStatus"
              >
              <span style={{ fontFamily: "Arial", fontSize: 16, fontWeight: 700, }}>
                {membershipStatus}
              </span>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Role"
              name="role"
              style={{ display: roleEnabled }}
              rules={[{ required: roleEnabled == "block"}]}
            >
              <Select options={roleOptions} onChange={onRoleChange}></Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={6}>
            <Form.Item
              label="Lifetime Member"
              name="isLifeTimeMember"
              valuePropName="checked"
              rules={[{ required: lifetimeMemberEnabled}]}
            >
              <Radio.Group
                onChange={onLifetimeChange}
                value={isLifeTimeMember}
                disabled={!lifetimeMemberEnabled}
              >
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="Deceased"
              name="deceased"
              valuePropName="checked"
              rules={[{ required: deceasedDateEnabled}]}
            >
              <Radio.Group
                onChange={onDeceasedChange}
                value={deceased}
                disabled={!deceasedDateEnabled}
              >
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Deceased Date"
              name="deceasedDate"
              style={{ display: deceasedDateVisible }}
              rules={[{ required: deceased == true, message: "Deceased Date is required" }]}
            >
              <DatePicker
                format={"MM/DD/YYYY"}
                onChange={onChangeDeceasedDateChange}
                style={{ width: "300px" }}
                disabled={!deceasedDateEnabled}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            {" "}
            <Form.Item 
              label="Family Status" 
              name="familyStatus" 
              rules={[{ required: familyStatusEnabled}]}
            >
              <Select
                options={familyStatusOptions}
                onChange={onFamilyStatusChange}
                disabled={!familyStatusEnabled}
              ></Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="Receive Physical Mail"
              name="receivePhysicalMail"
              valuePropName="checked"
              rules={[{ required: receiveMailEnabled}]}
            >
              <Radio.Group
                value={receivePhysicalMail}
                onChange={onReceiveMailChange}
                disabled={!receiveMailEnabled}
              >
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="Receive Digital Mail"
              name="receiveDigitalMail"
              valuePropName="checked"
              required
            >
              <Radio.Group
                value={receiveDigitalMail}
                onChange={onReceiveDigitalMailChange}
              >
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label="Prospect Source"
              name="prospectSource"
              style={{ display: prospectSourceEnabled }}
              rules={[{ required: prospectSourceEnabled == "block"}]}
            >
              <Select
                options={prospectSourceOptions}
                onChange={onProspectSourceChange}
              ></Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24} style={{ textAlign: "right" }}>
            <Button
              htmlType="submit"
              className="confirmButton"
              disabled={isSaveDisabled}
              size="middle"
            >
              Save
            </Button>
          </Col>
        </Row>
      </Form>
      <Dues 
        userData={userData}
        isSameAdminUser={isSameAdminUser}
        onFinishDues={onFinishDues} 
        />
      <MembersList 
        members={familyMembers} 
        user={userData}
        isSameAdminUser={isSameAdminUser}
        familyMembersEnabled={familyMembersEnabled}
        onFinishMembersList={onFinishMembersList} 
      />
    </Card>
  );
}

export default Membership;
